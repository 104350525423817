import React, { Component, useState } from 'react';
import axios from 'axios'
import { APIURL } from "./../../Constant/config"
import filterIcon from "../../assets/filter.png"
import LeftSide from '../layout/LeftSide';
import Navbar from '../layout/Navbar';
import TreeMap from '../tree_map/TreeMap'
import Modal from 'react-bootstrap/Modal';
import ChartsDropdown from "../layout/ChartsDropdown"

const useForceUpdate = () => useState()[1];

export default function Index() {
  const [rerender, setRerender] = useState(false);
  const [labels, setLabels] = useState([
    { value: 1, label: "One" },
    { value: 2, label: "Two" },
    { value: 3, label: "Three" }
  ])
  const [openModel, setOpenModel] = useState(false)
  const [phaseImportanceLevelId, setPhaseImportanceLevelId] = useState([])
  const [filterRender, setfilterRender] = useState(false)

  const handleFilterModal = () => {
    setOpenModel(!openModel)
    setfilterRender(true)
  }
  const handleModalClose = () => {
    setOpenModel(!openModel)
    setfilterRender(true)
  }

  const handlePhaseImportanceLevel = (value) => {
    let selectedPhaseLevel = phaseImportanceLevelId
    let newId = value
    if (!selectedPhaseLevel.includes(newId)) {
      selectedPhaseLevel.push(newId);
    } else {
      selectedPhaseLevel.splice(selectedPhaseLevel.indexOf(newId), 1);
    }
    setRerender(!rerender);
    setfilterRender(false)
    setPhaseImportanceLevelId(selectedPhaseLevel)
  }
  return (
    <>
      <section className="dashboard_bg">
        <LeftSide />
        <div className="right_side">
          <Navbar />
          <div className="dashboard_section">
            <div className="sort_and_filter">
              <div className="left">
                <ChartsDropdown />
              </div>
              <div className="right">
                <div onClick={handleFilterModal} className="indicators filter_indicator" data-bs-toggle="modal" data-bs-target="#exampleModal">
                  <img alt='filter' src={filterIcon} className="me-1" />
                  <p className="mb-0 indicators_name" >Filters</p>
                </div>
              </div>
            </div>
            <div className="dashboard_data_area mt-4 p-3 mb-3">
              <TreeMap
                data={{
                  phaseImportanceLevelId: phaseImportanceLevelId,
                  filterRender:filterRender
                }}
              />
            </div>
          </div>
        </div>
        <Modal className="right" style={{opacity:"1"}} show={openModel} onHide={handleModalClose}>
          <Modal.Header closeButton>
            <Modal.Title><h5 className="modal-title d-flex align-items-center filter_modal_heading" id="exampleModalLabel"> <i className="ri-equalizer-fill me-3"></i> Filter</h5></Modal.Title>
          </Modal.Header>
          <Modal.Body className="p-0 right_side_modal">
            <div className='row m-0 mt-3'>
              <div className='col-12 p-0 px-3'>
                <p className='mb-0 modal_field_header'>Phase Importance Level</p>
                <div className='row m-0 mt-2'>
                  <div className='col-12 p-0 d-flex align-items-center justify-content-start'>
                    {
                      labels.map((item, idx) =>
                        <div key={idx} className="form-check form-check-inline me-4">
                          <input onChange={(e) =>handlePhaseImportanceLevel(item.value)} className="form-check-input checkbox_sz" type="checkbox" checked={phaseImportanceLevelId.includes(item.value)} id="flexCheckDefault" />
                          <label className="form-check-label radio_links" htmlFor="flexCheckDefault">{item.value}</label>
                        </div>
                      )}
                  </div>
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </section>
    </>
  )
}
