import React, { useState, useEffect, useRef } from "react";
import axios from 'axios'
import { APIURL } from "./../../Constant/config"
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import * as am4plugins_timeline from "@amcharts/amcharts4/plugins/timeline";
import * as am4plugins_bullets from "@amcharts/amcharts4/plugins/bullets";
import Modal from 'react-bootstrap/Modal';
import EmailgreenIcon from "../../assets/mail_green.png";
import AddFile from "../../assets/addFile.svg";
import line from "../../assets/line.fw.png"
import grid from "../../assets/grid.png"
import menu from "../../assets/menu.png"
import dateFormat from "dateformat";
import Spinner from 'react-bootstrap/Spinner';

const useForceUpdate = () => useState()[1];

export default function Snack(props) {
  const searchInput = useRef();

  const monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
  const [popUpdocumentGroups, setPopUpdocumentGroups] = useState([
    { id: 1, page: 0, color: "#FFC034", isvisible: 0, footer: 0, name: "Document Phase", sort_name: "Document", data: [], group_key: "document", count: 0 },
    { id: 2, page: 0, color: "#3A9743", isvisible: 0, footer: 0, name: "E-Mail Phase", sort_name: "E-Mail", data: [], group_key: "email", count: 0 },
    { id: 3, page: 0, color: "#000000", isvisible: 0, footer: 1, name: "Miscellaneous Phase", sort_name: "Miscellaneous Phase", data: [], group_key: "miscellaneous", count: 0 },
  ])
  const [month, setMonth] = useState(0)
  const [monthname, setmonthname] = useState("")
  const [year, setYear] = useState(new Date().getFullYear())
  const [totalcount, setTotalcount] = useState(0)
  const [sliderMonth, setSliderMonth] = useState("0")
  const [firstDay, setFirstDay] = useState("")
  const [lastDay, setlastDay] = useState("")
  const [selectedIds, setSelectedIds] = useState([])
  const [documentRecords, setDocumentRecords] = useState([])
  const [totalPageRecords, setTotalPageRecords] = useState(0)
  const [pageRecordCount, setpageRecordCount] = useState(0)
  const [isloading, setIsloading] = useState(false)
  const [show, setShow] = useState(false)
  const [scrollCheck, setscrollCheck] = useState(0)
  const [scrollStatus, setscrollStatus] = useState(true)
  const [scrollStop, setscrollStop] = useState(false)
  const [years, setyears] = useState([])
  const isFirstRender = useRef(true);
  const listInnerRef = useRef();
  const forceUpdate = React.useReducer(() => ({ useForceUpdate }), {})[1]

  useEffect(() => {
    getYears()
    if (isFirstRender.current) {
      isFirstRender.current = false;
      return; // 👈️ return early if first render
    }
    APIHAndler()
    setmonthname(monthNames[sliderMonth])
  }, [props.data])

  const getYears = () => {
    const years = []
    for (let i = 2019; i <= new Date().getFullYear(); i++) {
      years.push({ value: i, label: i })
    }
    setyears(years)
  }
  function APIHAndler() {
    if (props.data.refresh == true) {
      setTimeout(() => {
        getSnakeData()
      }, 500);
    }
  }
  const handleYear = (e) => {
    setYear(e.target?.value);
    props.getYear(e.target?.value);
    setmonthname(monthNames[month]);
  }
  const getSnakeData = () => {
    var firstDay = new Date(year, month, 1);
    var lastDay = new Date(year, month + 1, 0);

    var startDay = dateFormat(firstDay, "yyyy-mm-dd").split("-")[2]
    var endDay = dateFormat(lastDay, "yyyy-mm-dd").split("-")[2]

    var min_date = new Date(year, month, startDay, 0, 1).getTime()
    var max_date = new Date(year, month, endDay, 23, 59).getTime()

    let params = {
      from: dateFormat(firstDay, "yyyy-mm-dd"),
      to: dateFormat(lastDay, "yyyy-mm-dd"),
      group_ids: props.data.activeGroupIds,
      filterValues: props.data.filters,
    }
    // axios api 
    axios.get(APIURL + '/api/tutorials/snake', { params })
      .then(resp => {
        let data = resp.data;
        getKpiData()
        getData(data, min_date, max_date)
      })
      .catch(error => {
        console.log(error);
      });
  }
  const getKpiData = () => {
    var firstDay = new Date(year, month, 1);
    let params = {
      date: dateFormat(firstDay, "yyyy-mm"),
      group_ids: props.data.activeGroupIds,
      filterValues: props.data.filters,
    }
    axios.get(APIURL + '/api/tutorials/kpi-card-data', { params })
      .then(resp => {
        let data = resp.data;
        let countTotal = 0;
        popUpdocumentGroups.map((item, index) => {
          popUpdocumentGroups[index].count = data[item.group_key] ? data[item.group_key] : 0
          countTotal = countTotal + item.count
          setTotalcount(countTotal)
        })
        forceUpdate()
      })
      .catch(error => {
        console.log(error);
      });
  }
  useEffect(() => getKpiData, [popUpdocumentGroups]);
  const getData = (Data, min_date, max_date) => {
    let chart = am4core.create("process", am4plugins_timeline.CurveChart);
    chart.curveContainer.padding(100, 20, 50, 20);
    chart.maskBullets = false;

    let colorSet = new am4core.ColorSet();

    chart.dateFormatter.inputDateFormat = "yyyy-MM-dd HH:mm";
    chart.dateFormatter.dateFormat = "dd";
    chart.data = Data

    chart.fontSize = 9;
    chart.tooltipContainer.fontSize = 10;

    let categoryAxis = chart.yAxes.push(new am4charts.CategoryAxis());
    categoryAxis.dataFields.category = "category";
    categoryAxis.renderer.labels.template.paddingRight = 25;
    categoryAxis.renderer.minGridDistance = 10;
    categoryAxis.renderer.innerRadius = 10;
    categoryAxis.renderer.radius = 20;

    let dateAxis = chart.xAxes.push(new am4charts.DateAxis());


    dateAxis.renderer.points = getPoints()
    dateAxis.renderer.autoScale = false;
    dateAxis.renderer.autoCenter = false;
    dateAxis.renderer.minGridDistance = 70;
    dateAxis.baseInterval = { count: 5, timeUnit: "minute" };
    dateAxis.renderer.tooltipLocation = 0;
    dateAxis.renderer.line.strokeDasharray = "1,4";
    dateAxis.renderer.line.strokeOpacity = 0.5;
    dateAxis.tooltip.background.fillOpacity = 0.2;
    dateAxis.tooltip.background.cornerRadius = 5;
    dateAxis.tooltip.label.fill = new am4core.InterfaceColorSet().getFor("alternativeBackground");
    dateAxis.tooltip.label.paddingTop = 7;
    dateAxis.endLocation = 0;
    dateAxis.startLocation = -0.5;
    dateAxis.min = min_date;
    dateAxis.max = max_date;
    dateAxis.tooltip.disabled = true;

    let labelTemplate = dateAxis.renderer.labels.template;
    labelTemplate.verticalCenter = "middle";
    labelTemplate.fillOpacity = 0.6;
    labelTemplate.background.fill = new am4core.InterfaceColorSet().getFor("background");
    labelTemplate.background.fillOpacity = 1;
    labelTemplate.fill = new am4core.InterfaceColorSet().getFor("text");
    labelTemplate.padding(7, 7, 7, 7);

    let series = chart.series.push(new am4plugins_timeline.CurveColumnSeries());
    series.columns.template.height = am4core.percent(30);

    series.dataFields.openDateX = "start_date_time";
    series.dataFields.dateX = "end_date_time";
    series.dataFields.categoryY = "category";

    series.baseAxis = categoryAxis;
    series.columns.template.strokeOpacity = 0;
    series.columns.template.fillOpacity = 0.6;

    let imageBullet1 = series.bullets.push(new am4plugins_bullets.PinBullet());
    imageBullet1.background.radius = 15;
    imageBullet1.locationX = 1;
    imageBullet1.propertyFields.stroke = "color";
    imageBullet1.background.propertyFields.fill = "color";
    imageBullet1.image = new am4core.Image();
    imageBullet1.label = new am4core.Label();
    imageBullet1.label.text = "{value}";
    imageBullet1.label.textAlign = "middle";
    imageBullet1.label.fontWeight = "bold";
    imageBullet1.label.fontSize = 13;
    imageBullet1.label.fill = "#FFF";
    imageBullet1.label.fillOpacity = 1;
    imageBullet1.label.strokeOpacity = 0;

    imageBullet1.image.scale = 0.7;
    imageBullet1.circle.radius = am4core.percent(100);
    imageBullet1.background.fillOpacity = 0.8;
    imageBullet1.background.strokeOpacity = 0;
    imageBullet1.dy = -2;
    imageBullet1.background.pointerBaseWidth = 10;
    imageBullet1.background.pointerLength = 10

    imageBullet1.events.on("hit", (event) =>
      handleSingleData(event.target.dataItem.dataContext, 1, false)
    );

    imageBullet1.background.adapter.add("pointerAngle", (value, target) => {
      if (target.dataItem) {
        let position = dateAxis.valueToPosition(target.dataItem.openDateX.getTime());
        return dateAxis.renderer.positionToAngle(position);
      }
      return value;
    });

    chart.scrollbarX = new am4core.Scrollbar();
    chart.scrollbarX.align = "center"
    chart.scrollbarX.width = am4core.percent(75);
    chart.scrollbarX.parent = chart.curveContainer;
    chart.scrollbarX.height = 300;
    chart.scrollbarX.orientation = "vertical";
    chart.scrollbarX.x = 130;
    chart.scrollbarX.y = -140;
    chart.scrollbarX.isMeasured = false;
    chart.scrollbarX.opacity = 1;

    //snack scroller 
    chart.scrollbarX.thumb.background.fill = am4core.color("#3FD1BE");
    chart.scrollbarX.thumb.background.fillOpacity = 1;
    chart.scrollbarX.startGrip.background.fill = am4core.color("#3FD1BE");
    chart.scrollbarX.endGrip.background.fill = am4core.color("#3FD1BE");
    chart.scrollbarX.thumb.background.fill = am4core.color("#d5e4e9");
    chart.scrollbarX.startGrip.icon.stroke = am4core.color("#fff");
    chart.scrollbarX.endGrip.icon.stroke = am4core.color("#fff");
    chart.scrollbarX.startGrip.background.states.getKey("hover").properties.fill = am4core.color("#20B19E");
    chart.scrollbarX.endGrip.background.states.getKey("hover").properties.fill = am4core.color("#20B19E");
    chart.scrollbarX.thumb.background.states.getKey("hover").properties.fill = am4core.color("#EDF7FA");
    chart.scrollbarX.startGrip.background.states.getKey("down").properties.fill = am4core.color("#20B19E");
    chart.scrollbarX.endGrip.background.states.getKey("down").properties.fill = am4core.color("#20B19E");
    chart.scrollbarX.thumb.background.states.getKey("down").properties.fill = am4core.color("#3FD1BE");

    let cursor = new am4plugins_timeline.CurveCursor();
    chart.cursor = cursor;
    cursor.xAxis = dateAxis;
    cursor.yAxis = categoryAxis;
    cursor.lineY.disabled = true;
    cursor.lineX.disabled = true;
    categoryAxis.cursorTooltipEnabled = false;
    chart.zoomOutButton.disabled = true;

    series.adapter.add("fill", function (fill, target) {
      if (fill instanceof am4core.Color) {
        return am4core.color(am4core.color("#1ADEC5"));
      }
      return fill;
    })
  }
  const getPoints = () => {
    let points = [{ x: -600, y: 200 }, { x: 0, y: 200 }];
    let w = 400
    let h = 400;
    let levelCount = 4;
    let radius = am4core.math.min(w / (levelCount - 1) / 2, h / 2);
    let startX = radius;
    for (let i = 0; i < 25; i++) {
      let angle = 0 + i / 25 * 90;
      let centerPoint = { y: 200 - radius, x: 0 }
      points.push({ y: centerPoint.y + radius * am4core.math.cos(angle), x: centerPoint.x + radius * am4core.math.sin(angle) });
    }
    for (let i = 0; i < levelCount; i++) {
      if (i % 2 !== 0) {
        points.push({ y: -h / 2 + radius, x: startX + w / (levelCount - 1) * i })
        points.push({ y: h / 2 - radius, x: startX + w / (levelCount - 1) * i })

        let centerPoint = { y: h / 2 - radius, x: startX + w / (levelCount - 1) * (i + 0.5) }
        if (i < levelCount - 1) {
          for (let k = 0; k < 50; k++) {
            let angle = -90 + k / 50 * 180;
            points.push({ y: centerPoint.y + radius * am4core.math.cos(angle), x: centerPoint.x + radius * am4core.math.sin(angle) });
          }
        }
        if (i === levelCount - 1) {
          points.pop();
          points.push({ y: -radius, x: startX + w / (levelCount - 1) * i })
          let centerPoint = { y: -radius, x: startX + w / (levelCount - 1) * (i + 0.5) }
          for (let k = 0; k < 25; k++) {
            let angle = -90 + k / 25 * 90;
            points.push({ y: centerPoint.y + radius * am4core.math.cos(angle), x: centerPoint.x + radius * am4core.math.sin(angle) });
          }
          points.push({ y: 0, x: 550 });
        }
      }
      else {
        points.push({ y: h / 2 - radius, x: startX + w / (levelCount - 1) * i })
        points.push({ y: -h / 2 + radius, x: startX + w / (levelCount - 1) * i })
        let centerPoint = { y: -h / 2 + radius, x: startX + w / (levelCount - 1) * (i + 0.5) }
        if (i < levelCount - 1) {
          for (var k = 0; k < 50; k++) {
            let angle = -90 - k / 50 * 180;
            points.push({ y: centerPoint.y + radius * am4core.math.cos(angle), x: centerPoint.x + radius * am4core.math.sin(angle) });
          }
        }
      }
    }
    return points;
  }
  const handleSlider = (e) => {
    setMonth(e.target.value === 0 ? 1 : parseInt(e.target.value))
    setSliderMonth(e.target.value)
    setmonthname(monthNames[e.target.value])
    getKpiData()
  }
  const handleEvent = (event) => {
    if (event.type !== "mousedown") {
      setmonthname(monthNames[sliderMonth])
      getSnakeData()
    }
  }
  const handleCloseModal = () => {
    setShow(false)
  }
  const handleViewListData = (groupId, viewType, viewButtons) => {
    let params = []
    var firstDay = new Date(year, month, 1);
    var lastDay = new Date(year, month + 1, 0);
    setFirstDay(firstDay)
    setlastDay(lastDay)
    console.log("lastdsdsd",lastDay)
    console.log("lastdsdsd",lastDay.getDay())

    params = {
      from: dateFormat(firstDay, "yyyy-mm-dd"),
      to: dateFormat(lastDay, "yyyy-mm-dd"),
      group_ids: groupId,
      page: 0,
      filterValues: props.data.filters,
    }
    setSelectedIds(groupId)
    setmonthname(monthNames[month])
    setPopUpdocumentGroups(popUpdocumentGroups)
    getDocumentDetailList(params)
    setShow(!show)
  }
  const handleSingleData = (snackData, viewType, viewButtons) => {
    let groupIds = [];
    let params = []

    groupIds.push(snackData.group_id)

    params = {
      from: dateFormat(snackData.date, "yyyy-mm-dd"),
      to: dateFormat(snackData.date, "yyyy-mm-dd"),
      group_ids: groupIds,
      page: 0,
      filterValues: props.data.filters,
    }

    popUpdocumentGroups.map((item, idx) => {
      if (item.id === snackData.group_id) {
        popUpdocumentGroups[idx].isvisible = 1
      } else {
        popUpdocumentGroups[idx].isvisible = 0
      }
    })
    setShow(true)
    setmonthname(monthNames[month])
    getDocumentDetailList(params)
  }
  const getDocumentDetailList = (params) => {
    if (params.page >= 0) {
      axios.get(APIURL + '/api/tutorials/doclist', { params })
        .then(resp => {
          let data = resp.data.res;
          let total_record = resp.data.total_records
          setTimeout(() => {
            popUpdocumentGroups.map((documentGroupsItem, index) => {
              if (documentGroupsItem.id == params.group_ids || (Array.isArray(params.group_ids) && params.group_ids.includes(documentGroupsItem.id))) {
                popUpdocumentGroups[index].data = data
                setIsloading(false)
              }
            });
            setDocumentRecords(data)
            setTotalPageRecords(total_record)
          }, 1000);
        })
        .catch(error => {
          console.log(error);
        });
    }
  }
  const handlePageGroup = (value, grp_id) => {
    setIsloading(true)
    setpageRecordCount(value == 0 ? pageRecordCount - 20 : pageRecordCount + 20)
    let params = {}
    popUpdocumentGroups.map((item, index) => {
      if (item.id == grp_id || (Array.isArray(grp_id) && grp_id.includes(item.id))) {
        popUpdocumentGroups[index].page = value == 0 ? item.page <= 0 ? 0 : item.page - 1 : item.page + 1;
        params = {
          from: dateFormat(firstDay, "yyyy-mm-dd"),
          to: dateFormat(lastDay, "yyyy-mm-dd"),
          group_ids: grp_id,
          page: item.page,
          filterValues: props.data.filters,
        }
      }
    })
    getDocumentDetailList(params)
  }
  function handleScroll(doc_Id) {
    if (totalPageRecords == pageRecordCount) {
      setscrollStop(false)
      setIsloading(false)
    }
    if (scrollStatus == true) {
      const { scrollTop, scrollHeight, clientHeight } = listInnerRef.current;
      setscrollCheck(document.getElementById("main-div").scrollTop)
      if (scrollCheck > document.getElementById("main-div").scrollTop) {
        if (document.getElementById("main-div").scrollTop == 0) {
          setTimeout(() => {
            if (document.getElementById("main-div").scrollTop == 0) {
              if(pageRecordCount == 0){
                document.getElementById("main-div").scrollTop = 0;
              }else{
                document.getElementById("main-div").scrollTop = clientHeight;
                handlePageGroup(0, doc_Id);
              }
              // document.getElementById("main-div").scrollTop = scrollStop == false ? 0 : clientHeight;
             
            }
          }, 1000);
        }
      }
      else {
         setTimeout(() => {
          if (listInnerRef.current) {
            const { scrollTop, scrollHeight, clientHeight } = listInnerRef.current;
            let scrollBottom = scrollHeight - 1
            if (scrollTop + clientHeight >= scrollBottom) {
              if (totalPageRecords >= pageRecordCount) {
                document.getElementById("main-div").scrollTop = clientHeight;
                handlePageGroup(1, doc_Id)
              } else {
                document.getElementById("main-div").scrollTop = scrollBottom;
              }
            }
          }
        }, 1000)
      }
    }
  }
  return (
    <>
      <div>
        <div className="selectfld">
          <select
            className="filter-dd dropdown ps-0 year_select"
            aria-label="Default select example"
            onChange={(e) => handleYear(e)}
          >
            {
              years.map((item) =>
                <option selected={item.value == new Date().getFullYear()} value={item.value}>{item.label}</option>
              )
            }
          </select>
        </div>
        <div className="detail_box p-4">
          <div className="row_one d-flex align-items-center">
            <div className="logo_box d-flex justify-content-center align-items-center">
              <img src={line} height="25" width="25" />
            </div>
            <div className="data ms-2">
              <p className="mb-0 bold_text">{monthNames[month] + " " + year}</p>
              <p className="mb-0 light_text">
                {totalcount} Phase</p>
            </div>
          </div>

          <div className="row_two mt-3 d-flex align-items-start justify-content-between">
            {
              popUpdocumentGroups.map((item, index) =>
                <React.Fragment>
                  <div key={index}
                    className="px-4 py-3 d-flex align-items-center justify-content-start graph_icon_section "
                  >
                    <div style={{ backgroundColor: item.color }} className="inside_circle d-flex align-items-center justify-content-center">
                      {
                        item.id == 1 ? <i className="ri-file-add-line"></i> :
                          item.id == 2 ? <i className="ri-mail-line"></i> :
                            item.id == 3 ? <img src={grid} height="12" width="12" /> : ""
                      }
                    </div>
                    <div className="inside_data ms-2" >
                      <p className="mb-0 bold_text_two">{item.sort_name}</p>
                      {
                        item.count > 0 && <p className="mb-0 light_text_two">{item.count} records</p>
                      }

                    </div>
                  </div>
                  <div className="line"></div>
                </React.Fragment>
              )
            }
          </div>
          <div className="row_three mt-4 d-flex align-items-center justify-content-end">
            <p className="mb-0 inside_link" onClick={(e) => handleViewListData(props.data.activeGroupIds, 1, true)}>View list</p>
          </div>
          <Modal
            show={show} onHide={() => handleCloseModal()}
            className="modal_xl"
            style={{opacity:"1"}}
            dialogClassName="modal-90w"
            aria-labelledby="example-custom-modal-styling-title"
          >
            <Modal.Header >
              <div className="d-flex justify-content-between w-100">
                <p className="mb-0 modal_xl_header d-flex align-items-center justify-content-start"><span className="bullet_point me-2"></span>{monthname + " " + year}</p>
              </div>

              <div className="bg-info">
              </div>
              <Modal.Header closeButton></Modal.Header>
            </Modal.Header>
            <Modal.Body>
              <React.Fragment>
                <div className="row m-0">
                  <div className="col-12 p-0">
                    <p className="mb-0 xl_inside_heading"></p>
                  </div>
                </div>
                <div className="row m-0 mt-2">
                  <div
                    className="col-12 p-0 position-relative"

                  >
                    <table
                      className="table tbl_mrg_btm scrollbody"
                      id="main-table"
                    >
                      <thead>
                        <tr>
                          <th className="table_head tb_head_one text-center" scope="col">#</th>
                          <th className="table_head tb_head_two" scope="col">Subject</th>
                          <th className="table_head tb_head_three" scope="col">Internal Note</th>
                          <th className="table_head tb_head_three tb_head_three_sm" scope="col">From</th>
                          <th className="table_head tb_head_three tb_head_three_sm" scope="col">To</th>
                          <th className="table_head tb_head_four" scope="col">Doc Type</th>
                          <th className="table_head tb_head_four" scope="col">Folder Type</th>
                          <th className="table_head tb_head_four" scope="col">Note Count </th>
                        </tr>
                      </thead>
                      <tbody className=""
                        id="main-div"
                        ref={listInnerRef}
                        onScroll={(e) => handleScroll(selectedIds)}
                      >
                        {
                          documentRecords.map((item, index) =>
                            <tr className="table_row">
                              <th className="tb_data tb_head_one text-center" scope="row">
                                {
                                  item.group_id == 1 ?
                                    <img src={AddFile} />
                                    :
                                    item.group_id == 2 ?
                                      <img src={EmailgreenIcon} width="16" />
                                      :
                                      item.group_id == 3 ?
                                        <img src={menu} />
                                        :
                                        ""
                                }
                              </th>
                              <td className="tb_data tb_head_two textflow_hdn"><div className="cnt" title={item.subject_text}>{item.subject_text}</div></td>
                              <td className="tb_data tb_head_three textflow_hdn"><div className="cnt" title={item.internal_note}>{item.internal_note}</div></td>
                              <td className="tb_data tb_head_three tb_head_three_sm">{item.from_user}</td>
                              <td className="tb_data tb_head_three tb_head_three_sm">{item.to_user}</td>
                              <td className="tb_data tb_head_four">{item.group_name}</td>
                              <td className="tb_data tb_head_four">{item.folder_type}</td>
                              <td className="tb_data tb_data_end tb_head_four"></td>
                            </tr>)
                        }
                      </tbody>
                    </table>
                  </div>
                </div>
                {isloading &&
                  <div className="loader_sp"><div className="spinner">
                    <Spinner size="md" animation="border" />
                    </div></div>}
              </React.Fragment>
            </Modal.Body>
          </Modal>
        </div>
        <div id="process" style={{ width: "100%", height: "500px" }} className="pb-3 mb-3"></div>
        {/* slider  */}
        <div className="sliderParent">
          <div className="slidecontainer">
            <input
              type="range"
              min="0"
              max="11"
              className="slider"
              ref={searchInput}
              value={sliderMonth}
              id="myRange"
              onChange={handleSlider}
              onMouseDown={handleEvent}
              onMouseUp={handleEvent}
            />
          </div>
        </div>
      </div>
    </>
  )
}

