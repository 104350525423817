import React, { useState } from 'react';
import filterIcon from "../../assets/filter.png"
import LeftSide from '../layout/LeftSide';
import Navbar from '../layout/Navbar';
import WorldCloud from './WorldCloud'
import Modal from 'react-bootstrap/Modal';
import Select from 'react-select';
import ChartsDropdown from "../layout/ChartsDropdown"

export default function Index() {
  const [openModel, setOpenModel] = useState(false)
  const [executedCopy, setExecutedCopy] = useState(true)
  const [matterPhaseOptions, setMatterPhaseOptions] = useState([
    { value: 1, label: "Airport City Meselesi" },
    { value: 2, label: "Akaryakit ve Liman Meselesi" },
    { value: 3, label: "Pist C Meselesi" },
    { value: 4, label: "Mania Meselesi" },
    { value: 5, label: "Toplam Sabit Yatirim Tutari ve Master Plan Revizyonu Meselesi" },
  ])
  const [status, setStatus] = useState(1)
  const [matterPhase, setMatterPhase] = useState("")
  const [filterRender, setfilterRender] = useState(false)
  const [render, setrender] = useState(false)

  const handleFilterModal = () => {
    setOpenModel(!openModel)
    setfilterRender(true)
  }
  const handleModalClose = () => {
    setOpenModel(!openModel)
    setfilterRender(true)
  }
  const handleMatterPhase = (value) => {
    setfilterRender(false)
    if (value == null) {
      setMatterPhase("")
    } else {
      setMatterPhase(value)
    }
    setrender(!render)
  }
  const handleView = (status) => {
    setMatterPhase("")
    if (status == 1) {
      setfilterRender(false)
      setStatus(status)
      setExecutedCopy(true)
    } else {
      setfilterRender(true)
      setStatus(status)
      setExecutedCopy(false)
    }
  }
  return (
    <>
      <section className="dashboard_bg">
        <LeftSide />
        <div className="right_side">
          <Navbar />
          <div className="dashboard_section">
            <div className="sort_and_filter">
              <div className="left">
                <ChartsDropdown />
              </div>
              <div className="right">
                <div onClick={handleFilterModal} className="indicators filter_indicator" data-bs-toggle="modal" data-bs-target="#exampleModal">
                  <img src={filterIcon} className="me-1" />
                  <p className="mb-0 indicators_name" >Filters</p>
                </div>
              </div>
            </div>
            <div className="dashboard_data_area mt-4 p-3 mb-3">
              <WorldCloud
                data={{
                  matterPhaseId: matterPhase.value,
                  executedCopy: executedCopy,
                  filterRender: filterRender
                }}
              />
            </div>
          </div>
        </div>
        <Modal className="right" style={{opacity:"1"}} show={openModel} onHide={handleModalClose}>
          <Modal.Header closeButton>
            <Modal.Title><h5 className="modal-title d-flex align-items-center filter_modal_heading" id="exampleModalLabel"> <i className="ri-equalizer-fill me-3"></i> Filter</h5></Modal.Title>
          </Modal.Header>
          <Modal.Body className="p-0 right_side_modal">
            <div className='row m-0'>
              <div className='col-12 p-0 px-3'>
                <hr className='mt-0 light_line' />
              </div>
            </div>
            <div className='row m-0 mt-3'>
              <div className='col-12 p-0 px-3'>
                <p className='mb-0 modal_field_header mb-2'>Filter Types</p>
                <div className="form-check mb-2">
                  <input onChange={(e) => handleView(1)} checked={status == 1} className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1" />
                  <label className="form-check-label" for="flexRadioDefault1">
                    Executive Copy
                  </label>
                </div>
                <div className="form-check">
                  <input onChange={(e) => handleView(2)} checked={status == 2} className="form-check-input ml-3" type="radio" name="flexRadioDefault" id="flexRadioDefault2" />
                  <label className="form-check-label" for="flexRadioDefault2">
                    Mater Phase
                  </label>
                </div>
              </div>
            </div>
            {
              status == 2 &&
              <div className='row m-0 mt-3'>
                <div className='col-12 p-0 px-3'>
                  <p className='mb-0 modal_field_header'>Mater Phase</p>
                  <Select
                    isClearable
                    className='modal_inputs mt-2'
                    value={matterPhase}
                    onChange={handleMatterPhase}
                    options={matterPhaseOptions}
                    placeholder="Select Mater Phase"
                  />
                </div>
              </div>
            }
          </Modal.Body>
        </Modal>
      </section>
    </>
  )
}
