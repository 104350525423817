import React, { useState, useEffect, useRef } from 'react';
import ReactFC from "react-fusioncharts";
import FusionCharts from "fusioncharts";
import Column2D from "fusioncharts/fusioncharts.charts";
import TimeSeries from "fusioncharts/fusioncharts.timeseries";

ReactFC.fcRoot(FusionCharts, TimeSeries);


// const jsonify = res => res.json();
//   const dataFetch = fetch(
//     "https://s3.eu-central-1.amazonaws.com/fusion.store/ft/data/different-plot-type-for-each-variable-measure-data.json"
//   ).then(jsonify);
//   const schemaFetch = fetch(
//     "https://s3.eu-central-1.amazonaws.com/fusion.store/ft/schema/different-plot-type-for-each-variable-measure-schema.json"
//   ).then(jsonify);




const jsonify = res => res.json();
const dataFetch = fetch(
  "https://s3.eu-central-1.amazonaws.com/fusion.store/ft/data/column-line-combination-data.json"
).then(jsonify);
const schemaFetch = fetch(
  "https://s3.eu-central-1.amazonaws.com/fusion.store/ft/schema/column-line-combination-schema.json"
).then(jsonify);

const dataSource = {

  navigator: {
    enabled: 0,
    // outputTimeFormat: "%B/%Y"
  },
  extensions: {
    standardRangeSelector: {
      enabled: 0
    },
    customRangeSelector: {
      enabled: 0
    }
  },
  chart: {
    showToolTip: "0",
    height: "100px",
    showYAxisValues: 0,
    // multiCanvas: false,
    showLegend: 0,
    yAxisName: 0,
    legend: {
      display: false,
      labels: {
        enabled: 0,
      }
    },


    // showValues: 0,
  },
  timeUnit: FusionCharts.Utils.DatetimeUnits.Year,
  caption: {
    //   text: "Sales Performance"
  },
  // binning: {"year": [1]},
  yaxis: [

    {

      showYAxisValues: 0,
      format: {
        defaultFormat: 0
      },
      label: {},
      plot: [

        {
          value: "Downloads",
          type: "line",
          connectNullData: "false",
          orientation: "right",
          title: " ",
        },

        {
          value: "Web Visits",
          type: "line",
          title: " ",
        },

        {
          value: "Web12",
          type: "line",
          // title: " ",
        }
      ],

    }
  ],

  xaxis: {
    plot: "Time",
    // initialInterval: true,


    binning: {
      // month: [1],
      // day: [1],
      // hour: [3, 6],
      // minute: []
    },
    outputTimeFormat: {
      //year: "",
      month: "%b",
      day: "%d",
      //hour: "",
      //minute: "",
      //second: "",
      //millisecond: ""
    }
  }
};




// STEP 4 - Creating the DOM element to pass the react-fusioncharts component
export default function Index() {
  const searchInput = useRef();
  const [sliderMonth, setSliderMonth] = useState(2022);
  // const [month, setMonth] = useState(0);
  const [min, setMin] = useState(2018);
  const [max, setMax] = useState(2026);
  const [activeYear, setActiveYear] = useState(new Date().getFullYear())
  const [timelineData, setTimelineData] = useState([])

  const [timeseriesDs, setTimeseriesDs] = useState({
    type: "timeseries",
    timeUnit: FusionCharts.Utils.DatetimeUnits.Year,
    renderAt: "container",
    width: "90%",
    height: "400",
    dataFormat: "json",
    // binning: {"year": [1]},
    dataSource
  })

  useEffect(() => {
    setTimeout(() => {
      onFetchData()
    }, 100);

  }, [max])






  var timeline = [];
  for (var i = 2000; i <= 2027; i++) {
    if (i >= min && i <= max) {
      var newArray = [];
      newArray.push("01-Jan-" + i);
      newArray.push(Math.floor((Math.random() * 10) + i));
      newArray.push(4 * i);
      timeline.push(newArray);
    }

  }
  console.log(timeline);
  //let timeline=[["01-Jan-2015","155","100"],["01-Jan-2016","222","100"],["01-Jan-2017","515","100"],["01-Jan-2018","55","100"],["01-Jan-2019","55","100"],["01-Jan-2020","55","100"],["01-Jan-2021","55","100"],["01-Jan-2022","55","100"],["01-Jan-2023","55","100"],["01-Jan-2024","155","100"],["01-Jan-2025","155","100"],["01-Jan-2015","155","100"],["01-Jan-2026","155","100"],["01-Jan-2027","155","100"]];


  const onFetchData = () => {
    console.log();
    Promise.all([dataFetch, schemaFetch]).then(res => {

      const data = timeline;
      const schema = res[1];
      const fusionTable = new FusionCharts.DataStore().createDataTable(
        data,
        schema
      );
      // setTimelineData(data);
      const timeseriesDsObj = Object.assign({}, timeseriesDs);
      timeseriesDsObj.dataSource.data = fusionTable;
      setTimeseriesDs(timeseriesDsObj);
    });
  }
  const handleSlider = (e) => {
    setSliderMonth(e.target.value)
   

  }
  const handleEvent = (event) => {
    if (event.type !== "mousedown") {

      console.log("handleEvent",event.target.value);
      console.log(sliderMonth);
      
    }
  }

  const handlePrevClick = () => {
    let NewsliderMonth = Number(sliderMonth) - 1;
    setSliderMonth(NewsliderMonth)
    let newMin = Number(min) - 1;
    let newMax = Number(max) - 1;
    setMin(newMin);
    setMax(newMax);


    console.log("prev", sliderMonth);
  }
  const handleNextClick = () => {
    let NewsliderMonth = Number(sliderMonth) + 1;
    setSliderMonth(NewsliderMonth)
    let newMin = Number(min) + 1;
    let newMax = Number(max) + 1;
    setMin(newMin);
    setMax(newMax);

  }
  useEffect(() => {
    setTimelineData()
  }, [max])



  return (
    <div className="position-relative">

      <div className="areachart"  style={{height:"100px"}}>
      <div className="sliderParent" style={{top:"330px"}}>
      <button className='btn-slide pre' disabled={min == 2000 ? true : false} onClick={handlePrevClick} ></button>
      <div className="slidecontainer">
        <input
          type="range"
          min={min}
          max={max}
          // step="1"
          className="slider"
          ref={searchInput}
          value={sliderMonth}
          id="myRange"
          onChange={handleSlider}
          onMouseDown={handleEvent}
          onMouseUp={handleEvent}
        />
      </div>
      <button className='btn-slide next' disabled={max == 2027 ? true : false} onClick={handleNextClick} ></button>
    </div>
        <ReactFC {...timeseriesDs} />

      </div>

    </div>


  )
}


