
/* Imports */
import React, { useEffect, useRef, useCallback } from 'react'
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import axios from 'axios'
import { APIURL } from "./../../Constant/config"

/* Chart code */
// Themes begin
am4core.useTheme(am4themes_animated);
// Themes end

export default function Density(props) {
var chart = am4core.create("chartdiv", am4charts.XYChart);
var data = [];
var value = 500;
for(var i = 0; i < 365; i++){
  var date = new Date("2022-01-01");
  date.setHours(0, 0, 0, 0);
  let j = Math.floor((Math.random() * 360) + 1);
  date.setDate(j);
  value -= Math.round((Math.random() < 0.5 ? 1 : -1) * Math.random() * 10);
  data.push({
    date:date,
    value: value,
  });
}
chart.data = data;
// Create axes
var dateAxis = chart.xAxes.push(new am4charts.DateAxis());
dateAxis.renderer.minGridDistance = 60;

var valueAxis1 = chart.yAxes.push(new am4charts.ValueAxis());

// Create series
var series = chart.series.push(new am4charts.ColumnSeries());
series.dataFields.valueY = "value";
series.dataFields.dateX = "date";
series.tooltipText = "{value}"
chart.cursor = new am4charts.XYCursor();
//chart.scrollbarX = new am4core.Scrollbar();
return (
  <>
    <div className=''>
      <div id='chartdiv' ></div>
    </div>
  </>
)
}



// /* Imports */
// import React, { useEffect, useRef, useCallback } from 'react'
// import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
// import * as am5 from "@amcharts/amcharts5";
// import * as am5xy from "@amcharts/amcharts5/xy";

// export default function Density(props) {

//     useEffect(() => {
//        /* Chart code */
// // Create root element
// // https://www.amcharts.com/docs/v5/getting-started/#Root_element
// let root = am5.Root.new("chartdiv");


// // Set themes
// // https://www.amcharts.com/docs/v5/concepts/themes/
// root.setThemes([
//   am5themes_Animated.new(root)
// ]);


// // Create chart
// // https://www.amcharts.com/docs/v5/charts/xy-chart/
// let chart = root.container.children.push(am5xy.XYChart.new(root, {
//   panX: true,
//   panY: true,
//   wheelX: "panX",
//   wheelY: "zoomX"
// }));


// // Add cursor
// // https://www.amcharts.com/docs/v5/charts/xy-chart/cursor/
// let cursor = chart.set("cursor", am5xy.XYCursor.new(root, {
//   behavior: "zoomX"
// }));
// cursor.lineY.set("visible", false);

// let date = new Date();
// date.setHours(0, 0, 0, 0);
// let value = 100;

// function generateData() {
//   value = Math.round((Math.random() * 10 - 5) + value);
//   am5.time.add(date, "day", 1);
//   return {
//     date: date.getTime(),
//     value: value
//   };
// }

// function generateDatas(count) {
//   let data = [];
//   for (var i = 0; i < count; ++i) {
//     data.push(generateData());
//   }
//   return data;
// }


// // Create axes
// // https://www.amcharts.com/docs/v5/charts/xy-chart/axes/
// let xAxis = chart.xAxes.push(am5xy.DateAxis.new(root, {
//   maxDeviation: 0,
//   baseInterval: {
//     timeUnit: "day",
//     count: 1
//   },
//   renderer: am5xy.AxisRendererX.new(root, {}),
//   tooltip: am5.Tooltip.new(root, {})
// }));

// let yAxis = chart.yAxes.push(am5xy.ValueAxis.new(root, {
//   renderer: am5xy.AxisRendererY.new(root, {})
// }));


// // Add series
// // https://www.amcharts.com/docs/v5/charts/xy-chart/series/
// let series = chart.series.push(am5xy.ColumnSeries.new(root, {
//   name: "Series",
//   xAxis: xAxis,
//   yAxis: yAxis,
//   valueYField: "value",
//   valueXField: "date",
//   tooltip: am5.Tooltip.new(root, {
//     labelText: "{valueY}"
//   })
// }));



// // Add scrollbar
// // https://www.amcharts.com/docs/v5/charts/xy-chart/scrollbars/
// chart.set("scrollbarX", am5.Scrollbar.new(root, {
//   orientation: "horizontal"
// }));

// let data = generateDatas(365        );
// series.data.setAll(data);


// // Make stuff animate on load
// // https://www.amcharts.com/docs/v5/concepts/animations/
// series.appear(1000);
// chart.appear(1000, 100);

//       }, [])
// return (
//     <>
//       <div className=''>
//       <div id='chartdiv' ></div>
//       </div>
//     </>
//   )

// }

///////////////////////////////////////////////////////////////////////////////////////////////////

// import React, { useState, useEffect, useRef, useCallback } from 'react'
// import ReactFC from "react-fusioncharts";
// import FusionCharts from "fusioncharts";
// import Column2D from "fusioncharts/fusioncharts.charts";
// import TimeSeries from "fusioncharts/fusioncharts.timeseries";
// import FusionTheme from "fusioncharts/themes/fusioncharts.theme.fusion";

// // Adding the chart and theme as dependency to the core fusioncharts
// ReactFC.fcRoot(FusionCharts, Column2D, FusionTheme,TimeSeries);

// const jsonify = res => res.json();
//   const dataFetch = fetch(
//     "https://s3.eu-central-1.amazonaws.com/fusion.store/ft/data/different-plot-type-for-each-variable-measure-data.json"
//   ).then(jsonify);
//   const schemaFetch = fetch(
//     "https://s3.eu-central-1.amazonaws.com/fusion.store/ft/schema/different-plot-type-for-each-variable-measure-schema.json"
//   ).then(jsonify);

//   const dataSource = {
//     chart: {},
//     caption: {
//       text: ""
//     },
//     yaxis: [
//       {
//         plot: {
//           value: "Document",
//           type: "column"
//         },
//         title: "Document",
//         // format: {
//         //   prefix: "$"
//         // }
//       },
//       {
//         plot: {
//           value: "EMail",
//           type: "column"
//         },
//         title: "EMail"
//       },
//       {
//         plot: {
//           value: "Miscellaneous",
//           type: "column"
//         },
//         title: "Miscellaneous"
//       }
//     ]
//   };

// // STEP 4 - Creating the DOM element to pass the react-fusioncharts component
// export default function Density(props) {

//   const [timeseriesDs, setTimeseriesDs] = useState({
//             type: "timeseries",
//             renderAt: "container",
//             width: "100%",
//             height: "600",
//             dataSource
//           })

//   useEffect(() => {

//     setTimeout(() => {
//       onFetchData()
//     }, 3000);
    
//   }, [])

//   const onFetchData = () => {
//     Promise.all([dataFetch, schemaFetch]).then(res => {
//       console.log(res);
//       //const data = res[0];
//       const data = 
        
//     [
//       [
//           "1/1/2018 0:00:00",
//           1044,
//           700,
//           6600168,
//           "0"
//       ],
//       [
//           "2/1/2018 0:02:52",
//           975,
//           1300,
//           4388475,
//           "1"
//       ],
//       [
//           "2/2/2018 0:05:45",
//           1020,
//           1500,
//           6865620,
//           "2"
//       ],
//       [
//           "3/2/2018 0:08:38",
//           1048,
//           2000,
//           4482296,
//           "3"
//       ],
//       [
//           "3/3/2018 0:11:31",
//           1689,
//           2400,
//           16315740,
//           "4"
//       ],
//       [
//           "4/3/2018 0:14:24",
//           1291,
//           1400,
//           12709895,
//           "5"
//       ],
//       [
//           "4/4/2018 0:17:16",
//           1747,
//           3400,
//           15473179,
//           "6"
//       ],
//       [
//           "5/4/2018 0:20:09",
//           1891,
//           1200,
//           15640461,
//           "7"
//       ],
//       [
//           "5/5/2018 0:23:02",
//           1287,
//           2200,
//           1822392,
//           "8"
//       ],
//       [
//           "6/5/2018 0:25:55",
//           465,
//           1600,
//           4126875,
//           "9"
//       ],
//       [
//           "6/6/2018 0:28:48",
//           405,
//           2300,
//           626130,
//           "10"
//       ],
//       [
//           "7/6/2018 0:31:40",
//           809,
//           1200,
//           4476197,
//           "11"
//       ],
//       [
//           "7/7/2018 0:34:33",
//           1229,
//           800,
//           2218345,
//           "12"
//       ],
//       [
//           "8/7/2018 0:37:26",
//           797,
//           300,
//           2726537,
//           "13"
//       ],
//       [
//           "8/8/2018 0:40:19",
//           1825,
//           800,
//           17326550,
//           "14"
//       ],
//       [
//           "9/8/2018 0:43:12",
//           1716,
//           400,
//           3469752,
//           "15"
//       ],
//       [
//           "9/9/2018 0:46:04",
//           673,
//           1400,
//           1117853,
//           "16"
//       ],
//       [
//           "10/9/2018 0:48:57",
//           689,
//           2400,
//           5460325,
//           "17"
//       ],
//       [
//           "10/10/2018 0:51:50",
//           927,
//           1400,
//           4332798,
//           "18"
//       ],
//       [
//           "11/10/2018 0:54:43",
//           777,
//           900,
//           2885001,
//           "19"
//       ],
//       [
//           "11/11/2018 0:57:36",
//           1221,
//           1100,
//           6411471,
//           "20"
//       ],
//       [
//           "12/12/2018 1:00:28",
//           569,
//           900,
//           2235601,
//           "21"
//       ],
//       [
//           "1/1/2019 1:03:21",
//           822,
//           650,
//           6442836,
//           "22"
//       ],
//       [
//           "2/2/2019 1:06:14",
//           1378,
//           2400,
//           6925828,
//           "23"
//       ],
//       [
//           "3/3/2019 1:09:07",
//           1921,
//           1100,
//           6143358,
//           "24"
//       ],
//       [
//           "4/4/2019 1:12:00",
//           421,
//           1200,
//           2363494,
//           "25"
//       ],
//       [
//           "5/5/2019 1:14:52",
//           160,
//           2000,
//           520480,
//           "26"
//       ],
//       [
//           "6/6/2019 1:17:45",
//           843,
//           1800,
//           1949016,
//           "27"
//       ],
//       [
//           "7/7/2019 1:20:38",
//           432,
//           1500,
//           4175280,
//           "28"
//       ],
//       [
//           "7/7/2019 1:23:31",
//           1295,
//           1300,
//           10186470,
//           "29"
//       ],
//       [
//           "8/8/2019 1:26:24",
//           536,
//           1700,
//           1273000,
//           "30"
//       ],
//       [
//           "8/8/2019 1:29:16",
//           500,
//           1500,
//           1451000,
//           "31"
//       ],
//       [
//           "9/9/2019 1:32:09",
//           819,
//           1500,
//           3567564,
//           "32"
//       ],
//       [
//           "10/10/2019 1:35:02",
//           1393,
//           1100,
//           8167159,
//           "33"
//       ],
//       [
//           "11/11/2019 1:37:55",
//           78,
//           1300,
//           697008,
//           "34"
//       ],
//       [
//           "12/12/2019 1:40:48",
//           1775,
//           1500,
//           5822000,
//           "35"
//       ],
//       [
//           "1/1/2020 1:43:40",
//           976,
//           2100,
//           9286640,
//           "36"
//       ],
//       [
//           "2/2/2020 1:46:33",
//           1831,
//           1100,
//           1975649,
//           "37"
//       ],
//       [
//           "3/2/2020 1:49:26",
//           1554,
//           1800,
//           5496498,
//           "38"
//       ],
//       [
//           "4/3/2020 1:52:19",
//           1426,
//           1000,
//           12762700,
//           "39"
//       ],
//       [
//           "5/3/2020 1:55:12",
//           1019,
//           1400,
//           2802250,
//           "40"
//       ],
//       [
//           "6/4/2020 1:58:04",
//           42,
//           1800,
//           204666,
//           "41"
//       ],
//       [
//           "7/4/2020 2:00:57",
//           249,
//           1000,
//           740028,
//           "42"
//       ],
//       [
//           "8/5/2020 2:03:50",
//           794,
//           1200,
//           2692454,
//           "43"
//       ],
//       [
//           "8/6/2020 2:06:43",
//           87,
//           1500,
//           565065,
//           "44"
//       ],
//       [
//           "9/7/2020 2:09:36",
//           1931,
//           1000,
//           8820808,
//           "45"
//       ],
//       [
//           "9/8/2020 2:12:28",
//           743,
//           800,
//           1001564,
//           "46"
//       ],
//       [
//           "10/9/2020 2:15:21",
//           978,
//           400,
//           1317366,
//           "47"
//       ],
//       [
//           "11/10/2020 2:18:14",
//           975,
//           600,
//           1832025,
//           "48"
//       ],
//       [
//           "11/11/2020 2:21:07",
//           1309,
//           1800,
//           1343034,
//           "49"
//       ],
//       [
//           "11/12/2020 2:24:00",
//           212,
//           800,
//           764472,
//           "50"
//       ],
//   ];
    
//       //const schema = res[1];
//       const schema = [
//         {
//             "name": "Time",
//             "type": "date",
//             "format": "%-m/%-d/%Y %H:%M:%S",
//             "column": "Time",
//             "index": 0
//         },
//         {
//             "name": "Document",
//             "type": "number",
//             "column": "Document",
//             "index": 1
//         },
//         {
//             "name": "EMail",
//             "type": "number",
//             "column": "EMail",
//             "index": 2
//         },
//         {
//             "name": "Miscellaneous",
//             "type": "number",
//             "column": "Miscellaneous",
//             "index": 3
//         },
//         {
//             "name": "_row_id",
//             "type": "string"
//         }
//     ]
//       const fusionTable = new FusionCharts.DataStore().createDataTable(
//         data,
//         schema
//       );
//       const timeseriesDsObj = Object.assign({}, timeseriesDs);
//       timeseriesDsObj.dataSource.data = fusionTable;
//       setTimeseriesDs(timeseriesDsObj);
//     });
//   }

//   const changeData = () => {
//     console.log("AAAAAAAAAAAAAAAAAAAAAAAAA")
//   }

//   return (
//     <div>
//       <button onClick={() =>changeData()} >AAAAAAAAAAAAAAAAA</button>
//       <ReactFC {...timeseriesDs} />
//     </div>
//   )
// }



// class ChartViewer extends React.Component {
//   constructor(props) {
//     super(props);
//     this.onFetchData = this.onFetchData.bind(this);
//     this.state = {
//       timeseriesDs: {
//         type: "timeseries",
//         renderAt: "container",
//         width: "600",
//         height: "400",
//         dataSource
//       }
//     };
//   }

//   componentDidMount() {
//     this.onFetchData();
//   }

//   onFetchData() {
//     Promise.all([dataFetch, schemaFetch]).then(res => {
//       const data = res[0];
//       const schema = res[1];
//       const fusionTable = new FusionCharts.DataStore().createDataTable(
//         data,
//         schema
//       );
//       const timeseriesDs = Object.assign({}, this.state.timeseriesDs);
//       timeseriesDs.dataSource.data = fusionTable;
//       this.setState({
//         timeseriesDs
//       });
//     });
//   }

//   render() {
//     return (
//       <div>
//         {this.state.timeseriesDs.dataSource.data ? (
//           <ReactFC {...this.state.timeseriesDs} />
//         ) : (
//           "loading"
//         )}
//       </div>
//     );
//   }
// }



// import React, { useState, useEffect } from 'react'
// import axios from 'axios'
// import { APIURL } from "./../../Constant/config"
// import * as am4core from "@amcharts/amcharts4/core";
// import * as am4plugins_wordCloud from "@amcharts/amcharts4/plugins/wordCloud";

// import FusionCharts from 'fusioncharts';
// import Charts from 'fusioncharts/fusioncharts.charts';
// import ReactFC from 'react-fusioncharts';
// import FusionTheme from 'fusioncharts/themes/fusioncharts.theme.fusion';


// import TimeSeries from "fusioncharts/fusioncharts.timeseries";
// // import ReactFC from "../lib/ReactFC";

// ReactFC.fcRoot(FusionCharts, TimeSeries);

// export default function Density(props) {

//   const jsonify = res => res.json();
//   const dataFetch = fetch(
//     "https://s3.eu-central-1.amazonaws.com/fusion.store/ft/data/different-plot-type-for-each-variable-measure-data.json"
//   ).then(jsonify);
//   const schemaFetch = fetch(
//     "https://s3.eu-central-1.amazonaws.com/fusion.store/ft/schema/different-plot-type-for-each-variable-measure-schema.json"
//   ).then(jsonify);

//   const dataSource = {
//     chart: {},
//     caption: {
//       text: "Sales Performance"
//     },
//     yaxis: [
//       {
//         plot: {
//           value: "Sale Amount",
//           type: "area"
//         },
//         title: "Sale Amount",
//         format: {
//           prefix: "$"
//         }
//       },
//       {
//         plot: {
//           value: "Units Sold",
//           type: "column"
//         },
//         title: "Units Sold"
//       }
//     ]
//   };

//   const [timeseriesDs, setTimeseriesDs] = useState({
//     type: "timeseries",
//     renderAt: "container",
//     width: "600",
//     height: "400",
//     dataSource
//   })

//   useEffect(() => {
//     //onFetchData()
//   }, [])
  

//   const onFetchData = () => {
//     Promise.all([dataFetch, schemaFetch]).then(res => {
//       const data = res[0];
//       const schema = res[1];
//       const fusionTable = new FusionCharts.DataStore().createDataTable(
//         data,
//         schema
//       );
//       const timeseriesDs = Object.assign({}, timeseriesDs);
//       timeseriesDs.dataSource.data = fusionTable;
//       setTimeseriesDs(timeseriesDs)
//     });
//   }

//     useEffect(() => {
//         if (!props.data.filterRender) {
//             getWorldCloudData()
//         }
//     }, [props.data])
//     const getWorldCloud = (wordCloudData) => {
//         let chart = am4core.create("world_cloud", am4plugins_wordCloud.WordCloud);
//         let series = chart.series.push(new am4plugins_wordCloud.WordCloudSeries());

//         series.accuracy = 4;
//         series.step = 15;
//         series.rotationThreshold = 0.7;
//         series.maxCount = 200;
//         series.minWordLength = 2;
//         series.labels.template.tooltipText = "{word}: {value}";
//         series.fontFamily = "Courier New";
//         series.maxFontSize = am4core.percent(30);
//         var hoverState = series.labels.template.states.create("hover");
//         hoverState.properties.fill = am4core.color("#1ADEC5");

//         series.events.on("arrangestarted", function (ev) {
//             ev.target.baseSprite.preloader.show(0);
//         });

//         series.events.on("arrangeprogress", function (ev) {
//             ev.target.baseSprite.preloader.progress = ev.progress;
//         });
        
//         series.labels.template.events.on("hit", (event) =>
//             handleWordDetail(event.target.dataItem._dataContext.redirect_url)
//         )
//         // data format 
//         series.data = wordCloudData
//         series.dataFields.word = "tag";
//         series.dataFields.value = "weight";
//     }
//     const getWorldCloudData = () => {
//         let params = {
//             executed_copy: 1,
//         }
//         if (props.data.executedCopy == false) {
//             params = {
//                 matter_phase_id: props.data.matterPhaseId,
//             }
//         }
//         // axios api 

//         axios.get(APIURL + '/api/tutorials/word-cloud', { params })
//             .then(resp => {
//                 let data = resp.data;
//                 if (!props.data.filterRender) {
//                     getWorldCloud(data)
//                 }
//             })
//             .catch(error => {
//                 console.log(error);
//             });
//     }
//     const handleWordDetail = (url) => {
//         window.open(`${url}`, "_blank")
//     }
//     return (
//       <div>
//         {timeseriesDs.dataSource.data ? (
//           <ReactFC {...timeseriesDs} />
//         ) : (
//           "loading"
//         )}
//       </div>
//     )
// }
