import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { APIURL } from "./../../Constant/config"
import * as am4core from "@amcharts/amcharts4/core";
import * as am4plugins_wordCloud from "@amcharts/amcharts4/plugins/wordCloud";

export default function WorldCloud(props) {
    useEffect(() => {
        if (!props.data.filterRender) {
            getWorldCloudData()
        }
    }, [props.data])

    const getWorldCloud = (wordCloudData) => {
        let chart = am4core.create("world_cloud", am4plugins_wordCloud.WordCloud);
        let series = chart.series.push(new am4plugins_wordCloud.WordCloudSeries());

        series.accuracy = 4;
        series.step = 15;
        series.rotationThreshold = 0.7;
        series.maxCount = 200;
        series.minWordLength = 2;
        series.labels.template.tooltipText = "{word}: {value}";
        series.fontFamily = "Courier New";
        series.maxFontSize = am4core.percent(30);
        var hoverState = series.labels.template.states.create("hover");
        hoverState.properties.fill = am4core.color("#1ADEC5");

        series.events.on("arrangestarted", function (ev) {
            ev.target.baseSprite.preloader.show(0);
        });

        series.events.on("arrangeprogress", function (ev) {
            ev.target.baseSprite.preloader.progress = ev.progress;
        });
        
        series.labels.template.events.on("hit", (event) =>
            handleWordDetail(event.target.dataItem._dataContext.redirect_url)
        )
        // data format 
        series.data = wordCloudData
        series.dataFields.word = "tag";
        series.dataFields.value = "weight";
    }
    const getWorldCloudData = () => {
        let params = {
            executed_copy: 1,
        }
        if (props.data.executedCopy == false) {
            params = {
                matter_phase_id: props.data.matterPhaseId,
            }
        }
        // axios api 

        axios.get(APIURL + '/api/tutorials/word-cloud', { params })
            .then(resp => {
                let data = resp.data;
                if (!props.data.filterRender) {
                    getWorldCloud(data)
                }
            })
            .catch(error => {
                console.log(error);
            });
    }
    const handleWordDetail = (url) => {
        window.open(`${url}`, "_blank")
    }
    return (
        <>
            <div id='world_cloud' style={{ width: "100%", height: "500px" }} className="pb-3 mb-3"></div>
        </>
    )
}
