import React, { Component } from 'react'
import user from "../../assets/User.jpg";
import logo from "../../assets/Logo.svg";
import EmailWhiteIcon from "../../assets/envelope.png";
import BarIcon from '../../assets/bars.png';
import checkDocumentIcon from '../../assets/check_document.png';
import documentIcon from '../../assets/document.png';
import privacyIcon from '../../assets/privacy.png';
import FileRegular from '../../assets/DocumentBlack.svg';

export default class LeftSide extends Component {
  render() {
    return (
        <div className="left_side">
        <div>
          <div className="logo">
            <img src={logo} alt="" />
            <p className="brand_name">Mukavele</p>
          </div>

          <div className="menus">
            <ul>
              <li className='active'>
                <a href='#'>
                  <img height="20" width="24" src={BarIcon} alt="" />
                  <span className="mt-1">Panorama</span>
                </a>
              </li>
              <li>
                <a href='#'>
                  <img height="28" width="28" src={documentIcon} alt="" />
                  <span className="mt-1">Sözleşmelerv</span>
                </a>
              </li>
              <li>
                <a href='#'>
                  <img height="28" width="28" src={checkDocumentIcon} alt="" />
                  <span className="mt-1">Görevler</span>
                </a>
              </li>
              <li>
                <a href='#'>
                  <img height="25" width="25" src={EmailWhiteIcon} alt="" />
                  <span className="mt-1">E-Postalar</span>
                </a>
              </li>
              <li>
                <a href='#'>
                  <img height="28" width="28" src={FileRegular} alt="" />
                  <span className="mt-1">Raporlar</span>
                </a>
              </li>
              <li>
                <a href='#'>
                  <img height="28" width="28" src={privacyIcon} alt="" />
                  <span className="mt-1">Yönetim Paneli</span>
                </a>
              </li>
            </ul>
          </div>
        </div>

        <div className="profile">
          <div className="profile_bg">
            <img src={user} alt="" />
          </div>
        </div>

      </div>
    )
  }
}