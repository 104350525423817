import React, { useState, useEffect } from "react";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
am4core.useTheme(am4themes_animated);
am4core.addLicense("ch-custom-attribution");

export default function Timeline(props) {
  const [graphid, setGraphid] = useState("")
  const [colorcode, setColorcode] = useState("")
  const [footer, setFooter] = useState("")
  const [divElement, setDivElement] = useState([])

  useEffect(() => {
    timelineGraph(props.data.timelineData);
    // console.log(props.data.timelineData.data);
    const height = divElement.clientHeight;
    const width = divElement.clientWidth;
    document.documentElement.style.setProperty('--slider-height', `${height - 70}px`);
    document.documentElement.style.setProperty('--slider-width', `${width}px`);
  }, [props.data])


  const timelineGraph = (timedata) => {
// console.log(timedata);
    let graphid = "document";
    let colorset = timedata.color
    let colorcode = timedata.color
    let footer = timedata.footer
    setGraphid(graphid)
    setColorcode(colorcode)
    setFooter(footer)

    var documentChart = am4core.create(graphid, am4charts.XYChart);
    documentChart.maskBullets = true;
    documentChart.data = timedata.data;
    console.log("---------------------------------",documentChart.data)

    // Create axes
    let dateAxis = documentChart.xAxes.push(new am4charts.DateAxis());
    // dateAxis.renderer.minGridDistance = 60;


    dateAxis.gridIntervals.setAll([
      { timeUnit: "year", count: 1 }
    ]);
    if (footer == 0) {
      dateAxis.renderer.labels.template.disabled = true;
    }
    dateAxis.renderer.grid.template.strokeWidth = 0;
    dateAxis.renderer.labels.template.fontSize = 10;

    

    let valueAxis = documentChart.yAxes.push(new am4charts.ValueAxis());
    valueAxis.renderer.labels.template.disabled = true;
    valueAxis.renderer.grid.template.strokeWidth = 0;

    // Create series
    let series = documentChart.series.push(new am4charts.LineSeries());
    series.dataFields.valueY = "value";
    series.dataFields.dateX = "date";
    series.tooltipText = "{value}"
    // series.stroke = am4core.color(colorset);
    // series.fill = series.stroke;
    // series.fillOpacity = 0.4;
    // series.fillOpacity = 0.5;
    series.stacked = true;
    series.tooltip.pointerOrientation = "vertical";
    documentChart.cursor = new am4charts.XYCursor();
    documentChart.cursor.snapToSeries = series;
    documentChart.cursor.xAxis = dateAxis;
    //documentChart.cursor.behavior = "none";


    let series2 = documentChart.series.push(new am4charts.LineSeries());
    series2.dataFields.valueY = "value1";
    series2.dataFields.dateX = "date";
    series2.tooltipText = "{value1}"
    // series.stroke = am4core.color(colorset);
    // series.fill = series.stroke;
    // series.fillOpacity = 0.4;
    // series.fillOpacity = 0.5;
    series2.stacked = true;
    series2.tooltip.pointerOrientation = "vertical";
    documentChart.cursor = new am4charts.XYCursor();
    documentChart.cursor.snapToSeries = series2;
    documentChart.cursor.xAxis = dateAxis;
    //documentChart.cursor.behavior = "none";

    let series3 = documentChart.series.push(new am4charts.LineSeries());
    series3.dataFields.valueY = "value2";
    series3.dataFields.dateX = "date";
    series3.tooltipText = "{value2}"
    // series.stroke = am4core.color(colorset);
    // series.fill = series.stroke;
    // series.fillOpacity = 0.4;
    // series.fillOpacity = 0.5;
    series3.stacked = true;
    series3.tooltip.pointerOrientation = "vertical";
    documentChart.cursor = new am4charts.XYCursor();
    documentChart.cursor.snapToSeries = series3;
    documentChart.cursor.xAxis = dateAxis;
    //documentChart.cursor.behavior = "none";
  }
  return (
    <>

      <div className="position-relative" ref={(divElement) => setDivElement(divElement)}>

        <div className="graph_area">
          <div id={graphid} style={{ width: "100%", height: footer == 0 ? "200px" : "100px" }}></div>

        </div>
      </div>
    </>
  )
}
