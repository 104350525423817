import React, { Component } from 'react'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default class Navbar extends Component {
  render() {
    return (
      <div className="breadcrumb mb-0 px-3">
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb mb-0 p-0">
            <li className="breadcrumb-item home_icon"> <a href="#"> <FontAwesomeIcon icon="fa-solid fa-house" /> </a></li>
            <li className="breadcrumb-item"> <a href="">CLİENT</a> </li>
            <li className="breadcrumb-item"> <a href="">MATTER NAME</a> </li>
            <li className="breadcrumb-item"> <a href="">CONTRACT ID</a> </li>
            <li className="breadcrumb-item active" aria-current="page"> <a href="">SÖZLEŞME SAFAHATİ</a> </li>
          </ol>
        </nav>
        <div className="toggle_bar">
          <button className="toggle_btn toggle_btn1"> <FontAwesomeIcon color='#00A696' icon="fa-solid fa-list" /> </button>
          <button className="toggle_btn toggle_btn2 active"> <FontAwesomeIcon color='#fff' icon="fa-solid fa-expand" /> </button>
        </div>
      </div>
    )
  }
}
