import React, { useState } from 'react';
import axios from 'axios'
import { APIURL } from "./../../Constant/config"
import Modal from 'react-bootstrap/Modal';
import Select from 'react-select';
import { MultiSelect } from "react-multi-select-component";
import filterIcon from "../../assets/filter.png"
import LeftSide from '../layout/LeftSide';
import Navbar from '../layout/Navbar';
import ChartsDropdown from "../layout/ChartsDropdown"
// import VerticalLine from './VerticalLine';
import VerticalLine from './TestVerticalLine';
import $ from 'jquery';

const labels = [
  { value: 1, label: "One" },
  { value: 2, label: "Two" },
  { value: 3, label: "Three" },
]
const matterPhaseOptions = [
  { value: 1, label: "Airport City Meselesi" },
  { value: 2, label: "Akaryakit ve Liman Meselesi" },
  { value: 3, label: "Pist C Meselesi" },
  { value: 4, label: "Mania Meselesi" },
  { value: 5, label: "Toplam Sabit Yatirim Tutari ve Master Plan Revizyonu Meselesi" },
]
const phaseAttributeOptions = [
  { value: 1, label: "One" },
  { value: 2, label: "Two" },
  { value: 3, label: "Three" },
]
const folderTypeOptions = [
  { value: 1, label: "Executed Copy" },
  { value: 2, label: "Versions" },
  { value: 3, label: "Meetin Minutes" },
  { value: 4, label: "Permits, Approvals and Licenses" },
  { value: 5, label: "Authorisation Documents" },
  { value: 6, label: "Stamp Duty Documents" },
]
const documentTypeOptions = [
  { value: 1, label: "Advance Payment Guarantee Form" },
  { value: 2, label: "Agreement Summary Table" },
  { value: 3, label: "Agreements List" },
  { value: 4, label: "Breakdown Report Form" },
  { value: 5, label: "Calisma Dosyasi" },
  { value: 6, label: "Commercial Board Approval Form" },
  { value: 7, label: "Communique" },
]
const miscelleneousPhaseOptions = [
  { value: 1, label: "Call" },
  { value: 2, label: "Visit" },
  { value: 3, label: "Note" },
  { value: 4, label: "Meeting" },
  { value: 5, label: "Message" },
]
const style = {
  control: base => ({
    ...base,
    border: 0,
    // This line disable the blue border
    boxShadow: 'none'
  })
};

export default function Index(props) {
  const [documentGroups, setDocumentGroups] = useState([
    { id: 1, color: "#FFC034", isvisible: 1, footer: 0, name: "Document Phase", sort_name: "Document", data: [], group_key: "document", },
    { id: 2, color: "#3A9743", isvisible: 1, footer: 0, name: "E-Mail Phase", sort_name: "E-Mail", data: [], group_key: "email", },
    { id: 3, color: "#000000", isvisible: 1, footer: 1, name: "Miscellaneous Phase", sort_name: "Miscellaneous Phase", data: [], group_key: "miscellaneous", },
  ])
  const [activeGroupIds, setActiveGroupIds] = useState([1, 2, 3])
  const [selectedIds, setSelectedIds] = useState([1, 2, 3])
  const [activeYear, setActiveYear] = useState(new Date().getFullYear())
  const [timelineData, setTimelineData] = useState([])
  const [openModel, setopenModel] = useState(false)
  const [groupFilterRender, setGroupFilterRender] = useState(false)
  const [matterPhase, setMatterPhase] = useState([])
  const [phaseAttribute, setPhaseAttribute] = useState([])
  const [phaseImportanceLevel, setPhaseImportanceLevel] = useState([])
  const [folderType, setfolderType] = useState([])
  const [folderTypeIds, setfolderTypeIds] = useState([])
  const [documentType, setdocumentType] = useState([])
  const [documentTypeIds, setdocumentTypeIds] = useState([])
  const [phaseAttributeIds, setphaseAttributeIds] = useState([])
  const [miscellaneousPhase, setmiscellaneousPhase] = useState([])
  const [miscellaneousPhaseIds, setMiscellaneousPhaseIds] = useState([])
  const [refresh, setrefresh] = useState(true)
  const [activeFilter, setactiveFilter] = useState(false)
  const [backdropFlag, setbackdropFlag] = useState(true)

  let inputElement = React.useRef(null)

  const groupFilter = (value) => {
    let Ids = selectedIds
    let newId = value

    if (selectedIds.length == 1 && selectedIds.includes(newId)) {
      return false
    }
    if (!Ids.includes(newId)) {
      Ids.push(newId);
    } else {
      Ids.splice(Ids.indexOf(newId), 1);
    }

    if (selectedIds.length == 1 && selectedIds.includes(2)) {
      setdocumentType([])
      handleMiscellaneousPhase([])
    }
    setActiveGroupIds(Ids)
    setSelectedIds(Ids)
    setGroupFilterRender(!groupFilterRender)
    setrefresh(true)
    setactiveFilter(true)
  }
  const handleFilterModal = () => {
    setbackdropFlag(true)
    setopenModel(true)
    setrefresh(false)
    setactiveFilter(false)
  }
  const handleModalClose = () => {
    $("#modal-filter").hide();
    setbackdropFlag(false)
    setopenModel(false)
    setrefresh(false)
    setactiveFilter(false)
  }
  const handleMatterPhase = (value) => {
    console.log("value",value)
    setrefresh(true)
    setactiveFilter(true)
    if (value == null) {
      setMatterPhase("")
      setphaseAttributeIds([])
      setPhaseAttribute([])
      setPhaseImportanceLevel([])
    } else {
      setMatterPhase(value)
    }
  }
  const handlePhaseAttribute = (phaseAttribute) => {
    let ids = [];
    phaseAttribute.map((item) => ids.push(item.value))
    setphaseAttributeIds(ids)
    setPhaseAttribute(phaseAttribute)
    setrefresh(true)
    setactiveFilter(true)
  };
  const handlePhaseImportanceLevel = (value) => {
    let selectedPhaseLevel = phaseImportanceLevel
    let newId = value

    if (!selectedPhaseLevel.includes(newId)) {
      selectedPhaseLevel.push(newId);
    } else {
      selectedPhaseLevel.splice(selectedPhaseLevel.indexOf(newId), 1);
    }
    setrefresh(true)
    setactiveFilter(true)
    setGroupFilterRender(!groupFilterRender);
    setPhaseImportanceLevel(selectedPhaseLevel)
  }
  const handleFolderType = (folderType) => {
    let ids = [];
    folderType.map((item) => ids.push(item.value))
    setfolderTypeIds(ids)
    setfolderType(folderType)
    setrefresh(true)
    setactiveFilter(true)
  }
  const handleDocumentType = (documentType) => {
    let ids = [];
    documentType.map((item) => ids.push(item.value))
    setdocumentTypeIds(ids)
    setdocumentType(documentType)
    setrefresh(true)
    setactiveFilter(true)
  }
  const handleMiscellaneousPhase = (miscellaneousPhase) => {
    let ids = [];
    miscellaneousPhase.map((item) => ids.push(item.value))
    setMiscellaneousPhaseIds(ids)
    setmiscellaneousPhase(miscellaneousPhase)
    setrefresh(true)
    setactiveFilter(true)
  }
  return (
    <>
      <section className="dashboard_bg">
        <LeftSide />
        <div className="right_side">
          <Navbar />
          <div className="dashboard_section">
            <div className="sort_and_filter">
              <div className="left">
                <ChartsDropdown />
              </div>
              <div className="right">
                {
                  documentGroups.map((item, idx) =>
                    <div key={idx} onClick={() => groupFilter(item.id)} className={"indicators me-2 " + (selectedIds.length > 0 ? activeGroupIds.includes(item.id) ? "" : "active" : "")} style={{ cursor: "pointer" }} >
                      <div className="indicat_colors  me-2" style={{ backgroundColor: item.color }}></div>
                      <p className="mb-0 indicators_name">{item.name}</p>
                    </div>
                  )
                }
                <div onClick={handleFilterModal} className="indicators filter_indicator">
                  <img src={filterIcon} className="me-1" />
                  <p className="mb-0 indicators_name" >Filters</p>
                </div>
              </div>
            </div>
            <div className="dashboard_data_area mt-4 p-3">
              <VerticalLine
                data={{
                  refresh: refresh,
                  activeGroupIds: activeGroupIds,
                  activeFilter: activeFilter,
                  filters: {
                    matterPhaseId: matterPhase.value,
                    phaseAttributeId: phaseAttributeIds,
                    phaseImportanceLevelId: phaseImportanceLevel,
                    folderTypeId: folderTypeIds,
                    documentTypeId: documentTypeIds,
                    miscellaneousPhaseId: miscellaneousPhaseIds,
                  }
                }}
              />
            </div>
          </div>
        </div>

        <Modal id="modal-filter" className="right" style={{ opacity: "1" }} show={openModel} onHide={handleModalClose}>
          <Modal.Header closeButton>
            <Modal.Title>
              <h5 className="modal-title d-flex align-items-center filter_modal_heading"> <i className="ri-equalizer-fill me-3"></i> Filter </h5>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="p-0 right_side_modal">
            <div className='row m-0'>
              <div className='col-12 p-0 px-3'>
                <hr className='mt-0 light_line' />
              </div>
            </div>
            <div className='row m-0 mt-3'>
              <div className='col-12 p-0 px-3'>
                <p className='mb-0 modal_field_header'>Matter Phase</p>
                <Select
                  blurInputOnSelect={true}
                  isClearable
                  className='modal_inputs mt-2'
                  value={matterPhase}
                  onChange={handleMatterPhase}
                  options={matterPhaseOptions}
                  placeholder="Select Matter Phase"
                  style={style}
                />
              </div>
            </div>
            {
              matterPhase != "" &&
              <React.Fragment>
                <div className='row m-0 mt-3'>
                  <div className='col-12 p-0 px-3'>
                    <p className='mb-0 modal_field_header'>Phase Attribute</p>
                    <MultiSelect
                      className='modal_inputs mt-2'
                      options={phaseAttributeOptions}
                      value={phaseAttribute}
                      onChange={handlePhaseAttribute}
                      disableSearch={true}
                      labelledBy="Select"
                      overrideStrings={{
                        selectSomeItems: "Select Phase Attribute",
                        allItemsAreSelected: "All Phase Attribute Selected",
                        selectAll: "Select All Phase Attribute",
                        search: "Search",
                      }}
                    />
                  </div>
                </div>

                <div className='row m-0 mt-3'>
                  <div className='col-12 p-0 px-3'>
                    <p className='mb-0 modal_field_header'>Phase Importance Level {phaseImportanceLevel}</p>
                    <div className='row m-0 mt-2'>
                      <div className='col-12 p-0 d-flex align-items-center justify-content-start'>
                        {
                          labels.map((item, idx) =>
                            <div key={idx} className="form-check form-check-inline me-4 d-flex align-items-center">
                              <input onChange={(e) => handlePhaseImportanceLevel(item.value)} className="form-check-input checkbox_sz" type="checkbox" checked={phaseImportanceLevel.includes(item.value)} id="flexCheckDefault" />
                              <label className="form-check-label radio_links" htmlFor="flexCheckDefault">{item.value}</label>
                            </div>
                          )}
                      </div>
                    </div>
                  </div>
                </div>
              </React.Fragment>
            }

            <div className='row m-0'>
              <div className='col-12 p-0'>
                <hr className='' />
              </div>
            </div>

            <div className='row m-0'>
              <div className='col-12 p-0 px-3'>
                <p className='mb-0 modal_field_header'>Folder Type</p>
                <MultiSelect
                  className='modal_inputs mt-2'
                  options={folderTypeOptions}
                  value={folderType}
                  onChange={handleFolderType}
                  disableSearch={true}
                  labelledBy="Select"
                  overrideStrings={{
                    selectSomeItems: "Select Folder Type",
                    allItemsAreSelected: "All Folder Type Selected",
                    selectAll: "Select All Folder Type",
                    search: "Search",
                  }}
                />
              </div>
            </div>
            {selectedIds.length == 1 && selectedIds.includes(3)
              ? "" :
              <div className='row m-0 mt-3'>
                <div className='col-12 p-0 px-3'>
                  <p className='mb-0 modal_field_header'>Document Type</p>
                  <MultiSelect
                    className='modal_inputs mt-2'
                    options={documentTypeOptions}
                    value={documentType}
                    onChange={handleDocumentType}
                    disableSearch={true}
                    labelledBy="Select"
                    overrideStrings={{
                      selectSomeItems: "Select Document Type",
                      allItemsAreSelected: "All Document Type Selected",
                      selectAll: "Select All Document Type",
                      search: "Search",
                    }}
                  />
                </div>
              </div>
            }

            <div className='row m-0'>
              <div className='col-12 p-0'>
                <hr className='' />
              </div>
            </div>

            {selectedIds.length == 1 && selectedIds.includes(1)
              ? "" :
              <div className='row m-0'>
                <div className='col-12 p-0 px-3'>
                  <p className='mb-0 modal_field_header'>Miscellaneous Phase</p>

                  <MultiSelect
                    className='modal_inputs mt-2'
                    options={miscelleneousPhaseOptions}
                    value={miscellaneousPhase}
                    onChange={handleMiscellaneousPhase}
                    disableSearch={true}
                    labelledBy="Select"
                    overrideStrings={{
                      selectSomeItems: "Select Miscellaneous Phase",
                      allItemsAreSelected: "All Miscellaneous Phase Selected",
                      selectAll: "Select All Miscellaneous Phase",
                      search: "Search",
                    }}
                  />
                </div>
              </div>
            }


          </Modal.Body>

        </Modal>

        {/* backdrop={false} onHide={handleModalClose} */}

        {/* <Modal className="right" style={{ opacity: "1" }} show={openModel}>
          <Modal.Header closeButton>
            <Modal.Title>
              <h5 className="modal-title d-flex align-items-center filter_modal_heading"> <i className="ri-equalizer-fill me-3"></i> Filter </h5></Modal.Title>
          </Modal.Header>
          <Modal.Body className="p-0 ">

            <div className='row m-0'>
              <div className='col-12 p-0 px-3'>
                <hr className='mt-0 light_line' />
              </div>
            </div>

            <div className='row m-0 mt-3'>
              <div className='col-12 p-0 px-3'>
                <p className='mb-0 modal_field_header'>Matter Phase</p>
                <Select
                  isClearable
                  className='modal_inputs mt-2'
                  value={matterPhase}
                  onChange={handleMatterPhase}
                  options={matterPhaseOptions}
                  placeholder="Select Matter Phase"
                />
              </div>
            </div>
            {
              matterPhase != "" &&
              <React.Fragment>
                <div className='row m-0 mt-3'>
                  <div className='col-12 p-0 px-3'>
                    <p className='mb-0 modal_field_header'>Phase Attribute</p>
                    <MultiSelect
                      className='modal_inputs mt-2'
                      options={phaseAttributeOptions}
                      value={phaseAttribute}
                      onChange={handlePhaseAttribute}
                      disableSearch={true}
                      labelledBy="Select"
                      overrideStrings={{
                        selectSomeItems: "Select Phase Attribute",
                        allItemsAreSelected: "All Phase Attribute Selected",
                        selectAll: "Select All Phase Attribute",
                        search: "Search",
                      }}
                    />
                  </div>
                </div>

                <div className='row m-0 mt-3'>
                  <div className='col-12 p-0 px-3'>
                    <p className='mb-0 modal_field_header'>Phase Importance Level {phaseImportanceLevel}</p>
                    <div className='row m-0 mt-2'>
                      <div className='col-12 p-0 d-flex align-items-center justify-content-start'>
                        {
                          labels.map((item, idx) =>
                            <div key={idx} className="form-check form-check-inline me-4 d-flex align-items-center">
                              <input onChange={(e) => handlePhaseImportanceLevel(item.value)} className="form-check-input checkbox_sz" type="checkbox" checked={phaseImportanceLevel.includes(item.value)} id="flexCheckDefault" />
                              <label className="form-check-label radio_links" htmlFor="flexCheckDefault">{item.value}</label>
                            </div>
                          )}
                      </div>
                    </div>
                  </div>
                </div>
              </React.Fragment>
            }

            <div className='row m-0'>
              <div className='col-12 p-0'>
                <hr className='' />
              </div>
            </div>

            <div className='row m-0'>
              <div className='col-12 p-0 px-3'>
                <p className='mb-0 modal_field_header'>Folder Type</p>
                <MultiSelect
                  className='modal_inputs mt-2'
                  options={folderTypeOptions}
                  value={folderType}
                  onChange={handleFolderType}
                  disableSearch={true}
                  labelledBy="Select"
                  overrideStrings={{
                    selectSomeItems: "Select Folder Type",
                    allItemsAreSelected: "All Folder Type Selected",
                    selectAll: "Select All Folder Type",
                    search: "Search",
                  }}
                />

              </div>
            </div>
            {selectedIds.length == 1 && selectedIds.includes(3)
              ? "" :
              <div className='row m-0 mt-3'>
                <div className='col-12 p-0 px-3'>
                  <p className='mb-0 modal_field_header'>Document Type</p>
                  <MultiSelect
                    className='modal_inputs mt-2'
                    options={documentTypeOptions}
                    value={documentType}
                    onChange={handleDocumentType}
                    disableSearch={true}
                    labelledBy="Select"
                    overrideStrings={{
                      selectSomeItems: "Select Document Type",
                      allItemsAreSelected: "All Document Type Selected",
                      selectAll: "Select All Document Type",
                      search: "Search",
                    }}
                  />
                </div>
              </div>
            }

            <div className='row m-0'>
              <div className='col-12 p-0'>
                <hr className='' />
              </div>
            </div>

            {selectedIds.length == 1 && selectedIds.includes(1)
              ? "" :
              <div className='row m-0'>
                <div className='col-12 p-0 px-3'>
                  <p className='mb-0 modal_field_header'>Miscellaneous Phase</p>

                  <MultiSelect
                    className='modal_inputs mt-2'
                    options={miscelleneousPhaseOptions}
                    value={miscellaneousPhase}
                    onChange={handleMiscellaneousPhase}
                    disableSearch={true}
                    labelledBy="Select"
                    overrideStrings={{
                      selectSomeItems: "Select Miscellaneous Phase",
                      allItemsAreSelected: "All Miscellaneous Phase Selected",
                      selectAll: "Select All Miscellaneous Phase",
                      search: "Search",
                    }}
                  />
                </div>
              </div>
            }
          </Modal.Body>
        </Modal> */}



      </section>
    </>
  )
}
