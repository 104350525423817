import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios'
import { APIURL } from "./../../Constant/config"
import { MultiSelect } from "react-multi-select-component";
import filterIcon from "../../assets/filter.png"
import Modal from 'react-bootstrap/Modal';
import Timeline from './Timeline';
import Snack from './Snack';
import LeftSide from '../layout/LeftSide';
import Navbar from '../layout/Navbar';
import Select from 'react-select';
import ChartsDropdown from "../layout/ChartsDropdown"

const labels = [
  { value: 1, label: "One" },
  { value: 2, label: "Two" },
  { value: 3, label: "Three" },
]
const matterPhaseOptions = [
  { value: 1, label: "Airport City Meselesi" },
  { value: 2, label: "Akaryakit ve Liman Meselesi" },
  { value: 3, label: "Pist C Meselesi" },
  { value: 4, label: "Mania Meselesi" },
  { value: 5, label: "Toplam Sabit Yatirim Tutari ve Master Plan Revizyonu Meselesi" },
]
const phaseAttributeOptions = [
  { value: 1, label: "One" },
  { value: 2, label: "Two" },
  { value: 3, label: "Three" },
]
const folderTypeOptions = [
  { value: 1, label: "Executed Copy" },
  { value: 2, label: "Versions" },
  { value: 3, label: "Meetin Minutes" },
  { value: 4, label: "Permits, Approvals and Licenses" },
  { value: 5, label: "Authorisation Documents" },
  { value: 6, label: "Stamp Duty Documents" },
]
const documentTypeOptions = [
  { value: 1, label: "Advance Payment Guarantee Form" },
  { value: 2, label: "Agreement Summary Table" },
  { value: 3, label: "Agreements List" },
  { value: 4, label: "Breakdown Report Form" },
  { value: 5, label: "Calisma Dosyasi" },
  { value: 6, label: "Commercial Board Approval Form" },
  { value: 7, label: "Communique" },

]
const miscelleneousPhaseOptions = [
  { value: 1, label: "Call" },
  { value: 2, label: "Visit" },
  { value: 3, label: "Note" },
  { value: 4, label: "Meeting" },
  { value: 5, label: "Message" },
]

export default function Index(props) {
  const searchInput = useRef();
  const monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];

  const [documentGroups, setDocumentGroups] = useState([
    { id: 1, color: "#FFC034", isvisible: 1, footer: 1, name: "Document Phase", sort_name: "Document", data: [], group_key: "document", },
    { id: 2, color: "#3A9743", isvisible: 1, footer: 1, name: "E-Mail Phase", sort_name: "E-Mail", data: [], group_key: "email", },
    { id: 3, color: "#000000", isvisible: 1, footer: 1, name: "Miscellaneous Phase", sort_name: "Miscellaneous Phase", data: [], group_key: "miscellaneous", },
  ])
  const [activeGroupIds, setActiveGroupIds] = useState([1, 2, 3])
  const [selectedIds, setSelectedIds] = useState([1, 2, 3])
  const [activeYear, setActiveYear] = useState(new Date().getFullYear())
  const [timelineData, setTimelineData] = useState([])
  const [openModel, setopenModel] = useState(false)
  const [groupFilterRender, setGroupFilterRender] = useState(false)
  const [matterPhase, setMatterPhase] = useState([])
  const [phaseAttribute, setPhaseAttribute] = useState([])
  const [phaseImportanceLevel, setPhaseImportanceLevel] = useState([])
  const [folderType, setfolderType] = useState([])
  const [folderTypeIds, setfolderTypeIds] = useState([])
  const [documentType, setdocumentType] = useState([])
  const [documentTypeIds, setdocumentTypeIds] = useState([])
  const [phaseAttributeIds, setphaseAttributeIds] = useState([])
  const [miscellaneousPhase, setmiscellaneousPhase] = useState([])
  const [miscellaneousPhaseIds, setMiscellaneousPhaseIds] = useState([])
  const [refresh, setrefresh] = useState(true)

  const [sliderMonth, setSliderMonth] = useState(2022);
  const [month, setMonth] = useState(0);
  const [min, setMin] = useState(2018);
  const [max, setMax] = useState(2026);
  const [newData, setnewData] = useState([])
  useEffect(() => {
    let params = { year: activeYear }
    getTimeLineRecords(params);
  }, [props.data])

  const timelineRecordFilter = (data, year) => {

    console.log("data ", data, year);
    let y = year
    let records = [];
    documentGroups.map((documentGroupsItem, index) => {
      let timeline = [];
      for (var i = 2000; i <= 2027; i++) {
        if (i >= min && i <= max) {
          timeline.push({
            "date": i + "-01-01",
            "value": Math.random() * 10,
            "value1": Math.random() * 5,
            "value2": Math.random() * 15,
          });
        }
      }

      records.push(timeline);
      documentGroups[index].data = timeline
    });
    return records
  }

  const staticRecordFilter = () => {
    let records = [];
    documentGroups.map((documentGroupsItem, index) => {
      let timeline = []

      for (var i = 2000; i <= 2027; i++) {
        if (i >= min && i <= max) {
          timeline.push({
            "date": i + "-01-01",
            "value": Math.random() * 10,
            "value1": Math.random() * 5,
            "value2": Math.random() * 15,
          });
          timeline.push({
            "date": i + "-12-15",
            "value": Math.random() * 10,
            "value1": Math.random() * 5,
            "value2": Math.random() * 15,
          });
        }
      }

      records.push(timeline);

      documentGroups[index].data = timeline
    });
    return records
  }

  const getTimeLineRecords = (params) => {
    let records = []

    axios.get(APIURL + '/api/tutorials/timeline', { params })
      .then(resp => {
        let data = resp.data;
        //records = timelineRecordFilter(data, params.year)
        records = staticRecordFilter()
        setTimelineData(records)
      })
      .catch(error => {
        // console.log(error);
      });
  }
  const groupFilter = (value) => {
    let Ids = selectedIds
    let newId = value

    if (selectedIds.length == 1 && selectedIds.includes(newId)) {
      return false
    }
    if (!Ids.includes(newId)) {
      Ids.push(newId);
    } else {
      Ids.splice(Ids.indexOf(newId), 1);
    }

    if (selectedIds.length == 1 && selectedIds.includes(2)) {
      setdocumentType([])
      handleMiscellaneousPhase([])
    }
    setActiveGroupIds(Ids)
    setSelectedIds(Ids)
    setGroupFilterRender(!groupFilterRender)
    setrefresh(true)
  }
  const handleFilterModal = () => {
    setopenModel(!openModel)
    setrefresh(false)
  }
  const handleModalClose = () => {
    setopenModel(!openModel)
    setrefresh(false)
  }
  const handleMatterPhase = (value) => {
    setrefresh(true)
    if (value == null) {
      setMatterPhase("")
    } else {
      setMatterPhase(value)
    }
  }
  const handlePhaseAttribute = (phaseAttribute) => {
    let ids = [];
    phaseAttribute.map((item) => ids.push(item.value))
    setphaseAttributeIds(ids)
    setPhaseAttribute(phaseAttribute)
    setrefresh(true)
  };
  const handlePhaseImportanceLevel = (value) => {
    let selectedPhaseLevel = phaseImportanceLevel
    let newId = value

    if (!selectedPhaseLevel.includes(newId)) {
      selectedPhaseLevel.push(newId);
    } else {
      selectedPhaseLevel.splice(selectedPhaseLevel.indexOf(newId), 1);
    }
    setrefresh(true)
    setGroupFilterRender(!groupFilterRender);

    setPhaseImportanceLevel(selectedPhaseLevel)
  }
  const handleFolderType = (folderType) => {
    let ids = [];
    folderType.map((item) => ids.push(item.value))
    setfolderTypeIds(ids)
    setfolderType(folderType)
    setrefresh(true)
  }
  const handleDocumentType = (documentType) => {
    let ids = [];
    documentType.map((item) => ids.push(item.value))
    setdocumentTypeIds(ids)
    setdocumentType(documentType)
    setrefresh(true)
  }
  const handleMiscellaneousPhase = (miscellaneousPhase) => {
    let ids = [];
    miscellaneousPhase.map((item) => ids.push(item.value))
    setMiscellaneousPhaseIds(ids)
    setmiscellaneousPhase(miscellaneousPhase)
    setrefresh(true)
  }
  function getYear(data) {
    let params = { year: data }
    // if(data != activeYear)
    // {
    getTimeLineRecords(params)
    setrefresh(true)
    // }

  }

  const handleSlider = (e) => {
    // setMonth(e.target.value === 0 ? 1 : parseInt(e.target.value))
    setSliderMonth(e.target.value)
    //setmonthname(monthNames[e.target.value])
    // getKpiData()
  }
  const handleEvent = (event) => {
    if (event.type !== "mousedown") {
      //setmonthname(monthNames[sliderMonth])
      // getSnakeData()
    }
  }

  const handlePrevClick = () => {
    let NewsliderMonth = Number(sliderMonth) - 1;
    setSliderMonth(NewsliderMonth)
    //setmonthname(monthNames[NewsliderMonth])



    let newMin = Number(min) - 1;
    let newMax = Number(max) - 1;
    setMin(newMin);
    setMax(newMax);

    console.log("prev");
  }
  const handleNextClick = () => {
    let NewsliderMonth = Number(sliderMonth) + 1;
    setSliderMonth(NewsliderMonth)
    //setmonthname(monthNames[NewsliderMonth])

    let newMin = Number(min) + 1;
    let newMax = Number(max) + 1;
    setMin(newMin); setMax(newMax);

    console.log("next");
  }

  useEffect(() => {
    let records = staticRecordFilter()
    setTimelineData(records)
  }, [max])




  return (
    <React.Fragment>
      <section className="dashboard_bg">
        <LeftSide />
        <div className="right_side">
          <Navbar />
          <div className="dashboard_section">
            <div className="sort_and_filter">
              <div className="left">
                <ChartsDropdown />
              </div>

              <div className="right">
                {
                  documentGroups.map((item, idx) =>
                    <div key={idx} onClick={() => groupFilter(item.id)} className={"indicators me-2 " + (selectedIds.length > 0 ? activeGroupIds.includes(item.id) ? "" : "active" : "")} style={{ cursor: "pointer" }} >
                      <div className="indicat_colors  me-2" style={{ backgroundColor: item.color }}></div>
                      <p className="mb-0 indicators_name">{item.name}</p>
                    </div>
                  )
                }

                <div onClick={() => handleFilterModal()} className="indicators filter_indicator" data-bs-toggle="modal" data-bs-target="#exampleModal">
                  <img src={filterIcon} className="me-1" />
                  <p className="mb-0 indicators_name" >Filters</p>
                </div>
              </div>
            </div>
            <div className="dashboard_data_area mt-4 p-3 mb-3 dashboard_data_area1" >

              <div className="areachart">
              <Timeline
                      data={{
                        timelineData: documentGroups[0],
                      }} />
                {
                 
                }

              </div>

              <div className="sliderParent">
                <button className='btn-slide pre' disabled={min == 2000 ? true : false} onClick={handlePrevClick} ></button>
                <div className="slidecontainer">
                  <input
                    type="range"
                    min={min}
                    max={max}
                    step="1"
                    className="slidersmall"
                    ref={searchInput}
                    value={sliderMonth}
                    id="myRange"
                    onChange={handleSlider}
                    onMouseDown={handleEvent}
                    onMouseUp={handleEvent}
                  />
                </div>
                <button className='btn-slide next' disabled={max == 2027 ? true : false} onClick={handleNextClick} ></button>
              </div>
            </div>
          </div>
        </div>

        {/* <!-- filters Modal --> */}
        <Modal className="right" style={{ opacity: "1" }} show={openModel} onHide={() => handleModalClose()}>
          <Modal.Header closeButton>
            <Modal.Title><h5 className="modal-title d-flex align-items-center filter_modal_heading" id="exampleModalLabel"> <i className="ri-equalizer-fill me-3"></i> Filter</h5></Modal.Title>
          </Modal.Header>
          <Modal.Body className="p-0 right_side_modal">
            <div className='row m-0'>
              <div className='col-12 p-0 px-3'>
                <hr className='mt-0 light_line' />
              </div>
            </div>

            <div className='row m-0 mt-3'>
              <div className='col-12 p-0 px-3'>
                <p className='mb-0 modal_field_header'>Matter Phase</p>
                <Select
                  isClearable
                  className='modal_inputs mt-2'
                  value={matterPhase}
                  onChange={handleMatterPhase}
                  options={matterPhaseOptions}
                  placeholder="Select Matter Phase"
                />
              </div>
            </div>
            {
              matterPhase != "" &&
              <React.Fragment>
                <div className='row m-0 mt-3'>
                  <div className='col-12 p-0 px-3'>
                    <p className='mb-0 modal_field_header'>Phase Attribute</p>
                    <MultiSelect
                      className='modal_inputs mt-2'
                      options={phaseAttributeOptions}
                      value={phaseAttribute}
                      onChange={handlePhaseAttribute}
                      disableSearch={true}
                      labelledBy="Select"
                      overrideStrings={{
                        selectSomeItems: "Select Phase Attribute",
                        allItemsAreSelected: "All Phase Attribute Selected",
                        selectAll: "Select All Phase Attribute",
                        search: "Search",
                      }}
                    />
                  </div>
                </div>

                <div className='row m-0 mt-3'>
                  <div className='col-12 p-0 px-3'>
                    <p className='mb-0 modal_field_header'>Phase Importance Level {phaseImportanceLevel}</p>
                    <div className='row m-0 mt-2'>
                      <div className='col-12 p-0 d-flex align-items-center justify-content-start'>
                        {
                          labels.map((item, idx) =>
                            <div key={idx} className="form-check form-check-inline me-4">
                              <input onChange={(e) => handlePhaseImportanceLevel(item.value)} className="form-check-input checkbox_sz" type="checkbox" checked={phaseImportanceLevel.includes(item.value)} id="flexCheckDefault" />
                              <label className="form-check-label radio_links" htmlFor="flexCheckDefault">{item.value}</label>
                            </div>
                          )}
                      </div>
                    </div>
                  </div>
                </div>
              </React.Fragment>
            }

            <div className='row m-0'>
              <div className='col-12 p-0'>
                <hr className='' />
              </div>
            </div>

            <div className='row m-0'>
              <div className='col-12 p-0 px-3'>
                <p className='mb-0 modal_field_header'>Folder Type</p>
                <MultiSelect
                  className='modal_inputs mt-2'
                  options={folderTypeOptions}
                  value={folderType}
                  onChange={handleFolderType}
                  disableSearch={true}
                  labelledBy="Select"
                  overrideStrings={{
                    selectSomeItems: "Select Folder Type",
                    allItemsAreSelected: "All Folder Type Selected",
                    selectAll: "Select All Folder Type",
                    search: "Search",
                  }}
                />

              </div>
            </div>
            {selectedIds.length == 1 && selectedIds.includes(3)
              ? "" :
              <div className='row m-0 mt-3'>
                <div className='col-12 p-0 px-3'>
                  <p className='mb-0 modal_field_header'>Document Type</p>
                  <MultiSelect
                    className='modal_inputs mt-2'
                    options={documentTypeOptions}
                    value={documentType}
                    onChange={handleDocumentType}
                    disableSearch={true}
                    labelledBy="Select"
                    overrideStrings={{
                      selectSomeItems: "Select Document Type",
                      allItemsAreSelected: "All Document Type Selected",
                      selectAll: "Select All Document Type",
                      search: "Search",
                    }}
                  />
                </div>
              </div>
            }

            <div className='row m-0'>
              <div className='col-12 p-0'>
                <hr className='' />
              </div>
            </div>

            {selectedIds.length == 1 && selectedIds.includes(1)
              ? "" :
              <div className='row m-0'>
                <div className='col-12 p-0 px-3'>
                  <p className='mb-0 modal_field_header'>Miscellaneous Phase</p>

                  <MultiSelect
                    className='modal_inputs mt-2'
                    options={miscelleneousPhaseOptions}
                    value={miscellaneousPhase}
                    onChange={handleMiscellaneousPhase}
                    disableSearch={true}
                    labelledBy="Select"
                    overrideStrings={{
                      selectSomeItems: "Select Miscellaneous Phase",
                      allItemsAreSelected: "All Miscellaneous Phase Selected",
                      selectAll: "Select All Miscellaneous Phase",
                      search: "Search",
                    }}
                  />
                </div>
              </div>
            }
          </Modal.Body>
        </Modal>
      </section>
    </React.Fragment>
  )
}
