import React from "react";
import { BrowserRouter as Router, Route } from 'react-router-dom';
import "./App.css";
import "./assets/css/style.css"
import SnakeTimeline from "./Components/snake_timeline/Index";
import TreeMap from "./Components/tree_map/Index";
import WorldCloud from "./Components/world_cloud/Index";
import Sankey from "./Components/sankey_diagram/Index";
import VerticalLine from "./Components/verticalLine/Index"
import Test from "./Components/Test"
import Density from "./Components/density/Index"
import DensityDummy from "./Components/density_dummy/Index"
import Slider from "./Components/Slider/Index"
import newTimeLine from "./Components/Slider/newTimeline";


export default function App() {
  return (
    <Router>
      <Route exact path="/" component={SnakeTimeline} />
      <Route exact path="/tree-map" component={TreeMap} />
      <Route exact path="/word-cloud" component={WorldCloud} />
      <Route exact path="/sankey" component={Sankey} />
      <Route exact path="/vertical-line" component={VerticalLine} />
      <Route exact path="/test" component={Test} />
      <Route exact path="/density" component={Density} />
      <Route exact path="/density-dummy" component={DensityDummy} />
      <Route exact path="/slider" component={Slider} />
      <Route exact path="/newslider" component={newTimeLine} />
    </Router>
  )
}
