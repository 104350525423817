import React, { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import { MultiSelect } from "react-multi-select-component";
import filterIcon from "../../assets/filter.png"
import LeftSide from '../layout/LeftSide';
import Navbar from '../layout/Navbar';
import ChartsDropdown from "../layout/ChartsDropdown"
import Sankey from './Sankey';
import $ from 'jquery';

const miscelleneousPhaseOptions = [
  { value: 1, label: "Call" },
  { value: 2, label: "Visit" },
  { value: 3, label: "Note" },
  { value: 4, label: "Meeting" },
  { value: 5, label: "Message" },
]

export default function Index() {
  const [openModel, setopenModel] = useState(false)
  const [miscellaneousPhase, setmiscellaneousPhase] = useState([])
  const [miscellaneousPhaseIds, setMiscellaneousPhaseIds] = useState([])
  const [refresh, setrefresh] = useState(true)

  const handleFilterModal = () => {
    setopenModel(!openModel)
    setrefresh(false)
  }
  const handleModalClose = () => {
    $("#modal-filter").hide();
    setopenModel(!openModel)
    setrefresh(false)
  }

  const handleMiscellaneousPhase = (miscellaneousPhase) => {
    let ids = [];
    miscellaneousPhase.map((item) => ids.push(item.value))
    setMiscellaneousPhaseIds(ids)
    setmiscellaneousPhase(miscellaneousPhase)
    setrefresh(true)
  }

  return (
    <>
      <section className="dashboard_bg">
        <LeftSide />
        <div className="right_side">
          <Navbar />
          <div className="dashboard_section">

            <div className="sort_and_filter">

              <div className="left">
                <ChartsDropdown />
              </div>

              <div className="right">

                <div onClick={() => handleFilterModal()} className="indicators filter_indicator" data-bs-toggle="modal" data-bs-target="#exampleModal">
                  <img src={filterIcon} className="me-1" />
                  <p className="mb-0 indicators_name" >Filters</p>
                </div>

              </div>

            </div>

            <div className="dashboard_data_area mt-4 p-3">

              <Sankey
                data={{
                  refresh: refresh,
                  openModel: openModel,
                  filters: {
                    miscellaneousPhaseId: miscellaneousPhaseIds,
                  }
                }}
              />
            </div>
          </div>
        </div>

        <Modal id="modal-filter" className="right" style={{ opacity: "1" }} show={openModel} onHide={() => handleModalClose()}>
          <Modal.Header closeButton>
            <Modal.Title><h5 className="modal-title d-flex align-items-center filter_modal_heading" id="exampleModalLabel"> <i className="ri-equalizer-fill me-3"></i> Filter</h5></Modal.Title>
          </Modal.Header>
          <Modal.Body className="p-0 right_side_modal">

            <div className='row m-0'>
              <div className='col-12 p-0'>
                <hr className='' />
              </div>
            </div>

            <div className='row m-0'>
              <div className='col-12 p-0 px-3'>
                <p className='mb-0 modal_field_header'>Miscellaneous Phase</p>
                <MultiSelect
                  className='modal_inputs mt-2'
                  options={miscelleneousPhaseOptions}
                  value={miscellaneousPhase}
                  onChange={handleMiscellaneousPhase}
                  disableSearch={true}
                  labelledBy="Select"
                  overrideStrings={{
                    selectSomeItems: "Select Miscellaneous Phase",
                    allItemsAreSelected: "All Miscellaneous Phase Selected",
                    selectAll: "Select All Miscellaneous Phase",
                    search: "Search",
                  }}
                />
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </section>
    </>
  )
}

