import React, { useState } from 'react';
import InputRange from 'react-input-range';
import "./Test.css";
const Test = () => {
  const [value, setValue] = useState(5);
  const [value4, setValue4] = useState({ min: 5, max: 10, });
  const [value5, setvalue5] = useState({ min: 3, max: 7, });


  const onChange = (e) => {
    setValue(e.target.value)
    console.log("hi");
  }
const onChangeComplete = ()=>{
  console.log(value);
}
  return (
    <div>
      <form className="form">
        <InputRange
          draggableTrack
          maxValue={20}
          minValue={0}
          onChange={onChange}
          onChangeComplete={onChangeComplete}
          value={value5} />
      </form>
      <div id="app"></div>
    </div>
    );

  }

export default Test






// import React, { useState, useRef } from 'react';
// import Button from 'react-bootstrap/Button';
// import Overlay from 'react-bootstrap/Overlay';

// function Example() {
//   const [show, setShow] = useState(false);
//   const target = useRef(null);

//   return (
//     <>
//       <Button variant="danger" ref={target} onClick={() => setShow(!show)}>
//         // Click me to see
//       </Button>
//       <Overlay target={target.current} show={show} placement="right">
//         {({ placement, arrowProps, show: _show, popper, ...props }) => (
//           <div
//             {...props}
//             style={{
//               position: 'absolute',
//               backgroundColor: 'rgba(255, 100, 100, 0.85)',
//               padding: '2px 10px',
//               color: 'white',
//               borderRadius: 3,
//               opacity:1,
//               ...props.style,
//             }}
//           >
//             Simple tooltip
//           </div>
//         )}
//       </Overlay>
//     </>
//   );
// }

// export default Example
