
import React, { useEffect, useState,useCallback } from 'react'
import axios from 'axios'
import { APIURL } from "./../../Constant/config"
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import bg from '../../assets/tr.png'
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import Spinner from 'react-bootstrap/Spinner';

export default function TreeMap(props) {

    const [rerender, setRerender] = useState(false);
    const [treeData, setTreeData] = useState([])
    const [allTreeData, setAllTreeData] = useState([])
    const [selectedMesel, setSelectedMesel] = useState("")
    const [isLoading, setIsLoading] = useState(false)
    const [meseleColor, setmeseleColor] = useState("")

    React.useEffect(() => {
        getTreeMapData()
    }, [props.data])

    const getTreeMapData = () => {
        let params = {
            contractId: 1,
            phase_importance_id: props.data.phaseImportanceLevelId
        }
        // axios api 
        axios.get(APIURL + '/api/tutorials/treemap/', { params })
            .then(resp => {
                let data = resp.data;
                const records = [];
                data.map((item, idx) => {
                    records.push({
                        "id": item.id,
                        "name": item.matter_phase_name,
                        "value": item.value,
                        "level": 1,
                    })
                });
                setTreeData(records);
                setAllTreeData(records);
                
                if(!props.data.filterRender){
                    getTreeMap(1, records);
                }
            })
            .catch(error => {
                console.log(error);
            });
    }
    const getTreeMap = (level, treeData) => {
        // create chart
        let chart = am4core.create("tree_map", am4charts.TreeMap);
        chart.hiddenState.properties.opacity = 0;
        chart.maxLevels = level;
        chart.dataFields.value = "value";
        chart.dataFields.name = "name";
        chart.dataFields.color = "color";
        chart.homeText = "";
        chart.zoomOutButton.disabled = true;
        chart.fill = am4core.color("#fc0");

        var level1 = chart.seriesTemplates.create("0");
        var level1_bullet = level1.bullets.push(new am4charts.LabelBullet());
        level1_bullet.locationY = 0.5;
        level1_bullet.locationX = 0.5;
        level1_bullet.label.text = "{name}";
        level1_bullet.label.fill = am4core.color("#fff");
        level1_bullet.fill = am4core.color("#fff");

        let level0SeriesTemplate = chart.seriesTemplates.create("0");
        // level0SeriesTemplate.strokeWidth = 8
        level0SeriesTemplate.bulletsContainer.hiddenState.properties.opacity = 1;
        level0SeriesTemplate.bulletsContainer.hiddenState.properties.visible = true;

        let columnTemplate = level0SeriesTemplate.columns.template;
        let hoverState = columnTemplate.states.create("hover");

        var level2 = chart.seriesTemplates.create("1");
        var level2_bullet = level2.bullets.push(new am4charts.LabelBullet());
        level2_bullet.locationY = 0.5;
        level2_bullet.locationX = 0.5;
        level2_bullet.label.text = "{name}";
        level2_bullet.label.fill = am4core.color("#fff");
        level2.fill = am4core.color("#fc0");

        hoverState.adapter.add("fill", function (fill, target) {
            if (fill instanceof am4core.Color) {
                return am4core.color(am4core.colors.brighten(fill.rgb, -0.2));
            }
            return fill;
        })

        let image = columnTemplate.createChild(am4core.Image);
        image.opacity = 0.15;
        image.align = "center";
        image.valign = "middle";
        image.width = am4core.percent(100);
        image.height = am4core.percent(100);
        image.events.on("hit", (event) => {
            if (level != 2) {
                getMeleseData(event.target.dataItem.dataContext._dataContext.id,event.target.dataItem.dataContext._dataContext.name, event.target.dataItem.dataContext.color.hex)
            } else {
                handleTreeRedirection(event.target.dataItem.dataContext._dataContext.url,)
            }
        });
        image.adapter.add("href", function (href, target) {
            let dataItem = target.parent.dataItem;
            if (dataItem) {
                return bg;
            }
        });
        let level1SeriesTemplate = chart.seriesTemplates.create("1");
        // level1SeriesTemplate.strokeWidth = 8;
        let bullet1 = level1SeriesTemplate.bullets.push(new am4charts.LabelBullet());
        bullet1.locationX = 0.5;
        bullet1.locationY = 0.5;
        bullet1.label.text = "{name}";
        bullet1.label.fill = am4core.color("#ffffff");

        // level2 series template
        let level2SeriesTemplate = chart.seriesTemplates.create("2");
        let bullet2 = level2SeriesTemplate.bullets.push(new am4charts.LabelBullet());
        bullet2.locationX = 0.5;
        bullet2.locationY = 0.5;
        bullet2.label.text = "{name}";
        bullet2.label.fill = am4core.color("#ffffff");

        chart.data = treeData

        setTimeout(() => {
            setIsLoading(false)
        }, 1000);
    }
    const getMeleseData = useCallback((Id,name, color) => {
        setSelectedMesel(name)
        setmeseleColor(color)
        setRerender(!rerender)
        setIsLoading(true)
        let params = {
            meseleId: Id
        }
        // axios api
        axios.get(APIURL + '/api/tutorials/treemap-folder/', { params })
            .then(resp => {
                let data = resp.data;
                let newArr = [];
                data.map((el) => {
                    newArr.push({
                        id: el.id,
                        name: el.folder_type,
                        value: el.value,
                        url: el.url,
                        level: 2,
                        color: color
                    })
                })
                setTreeData(newArr)
                getTreeMap(2, newArr)
            })
            .catch(error => {
                console.log(error);
            });
      }, [selectedMesel]);
    
    const handleTreeRedirection = (url) => {
        window.open(`${url}`, "_blank")
    }
    const handleTopLevel = () => {
        getTreeMap(1, allTreeData)
        setSelectedMesel("")
    }
    return (
        <>
            <div className='d-flex ' >
                <Breadcrumb>
                    <Breadcrumb.Item className={selectedMesel == "" && "active"} onClick={() => handleTopLevel()} to="#">All Mesels</Breadcrumb.Item>
                    <Breadcrumb.Item className={selectedMesel !== "" && "active"}>{selectedMesel}</Breadcrumb.Item>
                </Breadcrumb>
            </div>
            <div className="position-relative">
                {isLoading && <div className='tree_loader' style={{ background: meseleColor }}>
                    <Spinner  animation="border" />
                </div>}
                <div id='tree_map' style={{ width: "100%", height: "500px" }} className="pb-3 mb-3"></div>
            </div>
        </>
    )
}

