import React, { useState, useEffect, useRef, useCallback } from 'react'
import dummyImage from "../../assets/Sankey.PNG"
import { DateRangePicker } from 'rsuite';
import axios from 'axios'
import { APIURL } from "./../../Constant/config"
import dateFormat from "dateformat";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import Modal from 'react-bootstrap/Modal';
import EmailgreenIcon from "../../assets/mail_green.png";
import AddFile from "../../assets/addFile.svg";
import menu from "../../assets/menu.png"

import NoData from "../../assets/NoData.png";
import Spinner from 'react-bootstrap/Spinner';

import subDays from 'date-fns/subDays';
import addDays from 'date-fns/addDays';
import 'rsuite/dist/styles/rsuite-default.css';

const predefinedRanges = [
  {
    label: 'All time',
    value: [new Date(new Date().getFullYear() - 1, 0, 1), new Date()],
    placement: 'left'
  },
  {
    label: 'Today',
    value: [new Date(), new Date()],
    placement: 'left'
  },
  {
    label: 'Yesterday',
    value: [addDays(new Date(), -1), addDays(new Date(), -1)],
    placement: 'left'
  },
  {
    label: 'Last 7 days',
    value: [subDays(new Date(), 6), new Date()],
    placement: 'left'
  },
  {
    label: 'Last 14 days',
    value: [subDays(new Date(), 13), new Date()],
    placement: 'left'
  },
  {
    label: 'Last 30 days',
    value: [subDays(new Date(), 29), new Date()],
    placement: 'left'
  },
  {
    label: 'Last 90 days',
    value: [subDays(new Date(), 89), new Date()],
    placement: 'left'
  },
];

let level1Color = ["#FFC034", "#61ac69", "#333333"]
let level3Color = ["#C0D5DD"]

var chart = am4core.create("chartdiv", am4charts.SankeyDiagram);

export default function Sankey(props) {
  const listInnerRef = useRef();
  const [rerender, setRerender] = useState(false)
  const [isloading, setIsloading] = useState(false)
  const [show, setShow] = useState(false)
  const [totalPageRecords, setTotalPageRecords] = useState(0)
  const [pageRecordCount, setpageRecordCount] = useState(0)
  const [scrollCheck, setscrollCheck] = useState(0)
  const [scrollStatus, setscrollStatus] = useState(true)
  const [scrollStop, setscrollStop] = useState(false)
  const [viewList, setviewList] = useState([])
  const [pagecount, setpagecount] = useState(0)
  const [dateRange, setdateRange] = useState([])
  const [fromDate, setFromDate] = useState("")
  const [toDate, setToDate] = useState("")
  const [meseleId, setMeseleId] = useState("")
  const [heading, setHeading] = useState("")
  const [activeNodeLevel3, setactiveNodeLevel3] = useState([])
  const [activeNodeLevel2, setactiveNodeLevel2] = useState([])
  const [activeNodeLevel1, setactiveNodeLevel1] = useState([])
  const [activeChart, setActiveChart] = useState(false)
  const [noData, setNoData] = useState(false)
  const [sankeyData, setSankeyData] = useState([])
  const [fetchRecords, setfetchRecords] = useState([])
  const [activeGraph, setactiveGraph] = useState(false)

  const debounce = (func) => {
    let timer;
    return function (...args) {
      const context = this;
      if (timer) clearTimeout(timer);
      timer = setTimeout(() => {
        timer = null;
        func.apply(context, args);
      }, 2000);
    };
  };
  useEffect(() => {
    if (meseleId != "") {
      getSankey()
    }
  }, [activeGraph])

  useEffect(() => {
    setSankeyData(fetchRecords)
  }, [fetchRecords])

  useEffect(() => {
    return fromDate
  }, [fromDate, toDate])

  useEffect(() => {
    if (props.data.refresh == true && activeChart) {
      optimizedFn(meseleId, fromDate, toDate, props.data.filters)
    }
    localStorage.setItem('filterValues', JSON.stringify(props.data.filters));

  }, [props.data, pagecount, meseleId, activeChart])

  const getSankeyData = async (mesele_id, start, end, filters) => {
    setIsloading(true)
    let params = {
      filterValues: filters,
      mesele_id: mesele_id,
      from_date: start != "" ? dateFormat(start, "yyyy-mm-dd") : "",
      to_date: end != "" ? dateFormat(end, "yyyy-mm-dd") : "",
    }

    setFromDate(params.from_date)
    setToDate(params.to_date)
    setactiveNodeLevel3([])
    setactiveNodeLevel1([])
    setactiveNodeLevel2([])

    axios.get(APIURL + '/api/tutorials/sankey', { params })
      .then(async resp => {
        let response = resp.data
        let data = await prepare(response)
        setfetchRecords(data)
        setIsloading(false)
        setactiveGraph(true)
        if (!response.length > 0) {
          setNoData(true)
        } else {
          setNoData(false)
        }
        setIsloading(false)
      })
      .catch(error => {
        console.log(error);
      });
  }
  function prepare(data) {
    let rawData = data
    let sankeyDataNew = [
      { id: -3, level: 1, from: "Document", value: 0, node_key: "Document" + "-0", color: "#FFC034" },
      { id: -2, level: 1, from: "Email", value: 0, node_key: "Email" + "-0", color: "#61ac69" },
      { id: -1, level: 1, from: "Miscellaneous", value: 0, node_key: "Miscellaneous" + "-0", color: "#333333" }
    ];
    let ids = 1;
    rawData.map((rowLevel1) => {
      rowLevel1.children.map((rowLevel2, index) => {

        rowLevel2.children.map((rowLevel3, index1) => {
          let color = "#" + Math.floor(Math.random() * 16777215).toString(16);
          let p_id = ids
          let item = { p_id: 0, id: ids, level: 1, from: rowLevel1.type, to: rowLevel2.type, value: rowLevel3.count, node_key: rowLevel1.type + "-" + index1, color: color };
          sankeyDataNew.push(item)
          ids++;

          color = level3Color[0];
          item = { p_id: p_id, id: ids, level: 2, from: rowLevel2.type, to: rowLevel3.type, value: rowLevel3.count, node_key: rowLevel1.type + "-" + index1, color: color };
          sankeyDataNew.push(item)
          ids++;
        })
      })
    })
    let sankeyData = sankeyDataNew;
    // if (props.data.openModel == false && oldFilter != props.data.filters.miscellaneousPhaseId) {
    //   getSankey(sankeyData)
    //   console.log("yup")
    // } else {
    //   console.log("nope")
    //   // getSankey(sankeyData)
    // }
    chart.data = sankeyData
    return sankeyData
  }
  function handleMesele(event) {
    if (event.target.value != "") {
      setActiveChart(true)
      setMeseleId(event.target.value)
    } else {
      setActiveChart(false)
      return false
    }

    setactiveNodeLevel3([])
    setactiveNodeLevel1([])

    // setRerender(!rerender)
    // getSankeyData(event.target.value, fromDate, toDate)
  }
  const handleCloseModal = () => {
    setShow(false)
  }
  function handleScroll() {
    if (totalPageRecords == pageRecordCount) {
      setscrollStop(false)
      setIsloading(false)
    }
    if (scrollStatus == true) {
      const { scrollTop, scrollHeight, clientHeight } = listInnerRef.current;
      setscrollCheck(document.getElementById("main-div").scrollTop)
      if (scrollCheck > document.getElementById("main-div").scrollTop) {
        if (document.getElementById("main-div").scrollTop == 0) {
          setTimeout(() => {
            if (document.getElementById("main-div").scrollTop == 0) {
              if (pagecount == 0) {
                document.getElementById("main-div").scrollTop = 0;
              } else {
                document.getElementById("main-div").scrollTop = clientHeight;
                // setpageRecordCount(pageRecordCount - 20)
                getViewList(pagecount < 0 ? 0 : pagecount - 1, heading)
              }
            }
          }, 1000);
        }
      }
      else {
        setTimeout(() => {
          if (listInnerRef.current) {
            const { scrollTop, scrollHeight, clientHeight } = listInnerRef.current;
            let scrollBottom = scrollHeight - 1
            if (scrollTop + clientHeight >= scrollBottom) {
              document.getElementById("main-div").scrollTop = clientHeight;
              getViewList(pagecount + 1, heading)
            }
          }
        }, 1000)
      }
    }
  }
  async function getViewList(p_number, heading) {
    let activelink = []
    let deactivelink = []
    chart.links.each(async function (link) {
      if (link.dataItem.dataContext.id >= 0) {
        if (link._isHidden) {
          await deactivelink.push(link.dataItem.dataContext.from)
          await deactivelink.push(link.dataItem.dataContext.to)
        } else {
          await activelink.push(link.dataItem.dataContext)

        }
      }
    })

    let activeNodes = []
    activelink.map((val) => {
      activeNodes.push(val.from)
      activeNodes.push(val.to)
    })
    let nodes = [...new Set(activeNodes)]

    let filter = localStorage.getItem('filterValues');

    let params = {
      filterValues: filter,
      mesele_id: meseleId,
      from_date: fromDate,
      to_date: toDate,
      pageNo: p_number < 1 ? 0 : p_number,
      active_nodes: nodes,
      active_flow: heading.replaceAll('>', ',')
    }
    setHeading(heading)
    setShow(true)
    setpagecount(params.pageNo)
    axios.get(APIURL + '/api/tutorials/sankey-list', { params })
      .then((resp) => {
        setviewList(resp.data)
      })
      .catch(error => {
        console.log(error);
      });
  }
  function handleDateRange(value) {
    setdateRange(value)
    const start = value.filter((item, index) => index === 0);
    const end = value.filter((item, index) => index === 1);
    optimizedFn(meseleId, start, end, props.data.filters)
  }
  const getSankey = (data) => {
    let send_update_node = false
    let headings = "";
    chart.hiddenState.properties.opacity = 0; // this creates initial fade-in

    let hoverState = chart.links.template.states.create("hover");
    hoverState.properties.fillOpacity = 2;
    chart.dataFields.fromName = "from";
    chart.dataFields.toName = "to";
    chart.dataFields.value = "value";
    chart.dataFields.color = "color";
    chart.links.template.propertyFields.id = "id";
    chart.links.template.tooltipPosition = "pointer"

    chart.paddingRight = 50;
    var nodeTemplate = chart.nodes.template;
    nodeTemplate.inert = true;
    nodeTemplate.width = 20;
    nodeTemplate.draggable = false
    nodeTemplate.clickable = false


    nodeTemplate.showTooltipOn = "hover"
    nodeTemplate.tooltipText = "{name}:";
    nodeTemplate.showSystemTooltip = true;
    chart.tooltipPosition = "pointer"
    nodeTemplate.cursorOverStyle = am4core.MouseCursorStyle.pointer


    chart.links.template.events.on("over", function (event) {
      let link = event.target;
      let id = link.id
      let filterSankey = chart.data.filter((item) => item.id == id)
      let links = []
      let flowCountArr = []
      let flowCount = link.dataItem.dataContext.value
      let level = link.dataItem.dataContext.level
      let p_id = link.dataItem.dataContext.p_id


      chart.links.each(function (link) {
        if (level == 1) {
          if (link.dataItem.dataContext.p_id == id) {
            link.isHover = true;
            flowCount = flowCount + link.dataItem.dataContext.value
          }
        } else {
          if (link.dataItem.dataContext.id == p_id) {
            link.isHover = true;
            flowCount = flowCount + link.dataItem.dataContext.value
          }
        }
        if (link.dataItem.dataContext.node_key.indexOf(filterSankey[0].node_key) != -1) {
          links.push(link.dataItem.dataContext)
        }
      })

      flowCountArr.map((i) => flowCount = flowCount + i.value)
      chart.tooltipText = flowCount

      let first = ""
      let second = ""
      let third = ""
      links.map((item) => {
        var L1 = true;
        if (item.level == 1 && L1) {
          first = item
          L1 = false;
        }
        var L2 = true;
        if (item.level == 2 && L2) {
          second = item
          third = item.to
          L2 = false;
        }
        if (item.level != 2 && L2) {
          second = ""
          L2 = false;
        }
      })

      if (first.to == undefined) {
        headings = first.from
      }

      if (second.to == undefined) {

        headings = first.from + " > " + first.to + " > " + third
      }
    })
    chart.links.template.events.on("out", function (event) {
      let link = event.target;
      let id = link.id
      let filterSankey = sankeyData.filter((item) => item.id == id)
      chart.links.each(function (link) {
        link.isHover = false;
      })
    })
    chart.links.template.events.on("hit", function (event) {
      let link = event.target.dataItem.dataContext;
      if (meseleId != "") {
        console.log("run-----");
        getViewList(0, headings)
      }
    })
    chart.nodes.template.events.on("over", function (event) {

      let dataItem = event.target.dataItem;
      let level = event.target.dataItem.dataContext
      let from = dataItem.dataContext.from
      let to = dataItem.dataContext.to
      let node_color = dataItem.dataContext.color

      let id = dataItem.dataContext.node_key.split("-")[0]
      let data = chart.data

      let filtereData = data.filter((item) => item.from === id)
      let count = 0
      let reverseCount = 0
      let middleCount = 0
      let l1countArr = []
      let middlecountArr = []
      let l2countArr = []

      let middlelevelData = sankeyData.filter((item) => item.to == to)

      let level2Data = sankeyData.filter((item) => item.to == to)

      if (filtereData.length > 0 && level.level == 1) {
        if (!level1Color.includes(node_color) && !level3Color.includes(node_color)) {
          sankeyData.map((k) =>
            middlelevelData.map((p) => {
              chart.links.each(function (link) {
                if (link.dataItem.dataContext.from == p.to || link.dataItem.dataContext.to == p.from || link.dataItem.dataContext.to == to) {
                  link.isHover = true;
                  const index = middlecountArr.findIndex(object => object.id === link.dataItem.dataContext.id);
                  if (index === -1) {
                    middlecountArr.push({ id: link.dataItem.dataContext.id, value: link.dataItem.dataContext.value });
                  }
                  count = 0
                }
              })
            })
          )
        }
        else {
          filtereData.map((sItem) => {
            if (sItem.to) {
              data.map((vItem) => {
                if (vItem.from == sItem.to) {
                  chart.links.each(function (link, idx) {
                    if (link.dataItem.dataContext.node_key.indexOf(id) != -1) {
                      link.isHover = true;
                      const index = l1countArr.findIndex(object => object.id === link.dataItem.dataContext.id);
                      if (index === -1) {
                        l1countArr.push({ id: link.dataItem.dataContext.id, value: link.dataItem.dataContext.value });
                      }
                    }
                  });
                }
              })
            }
          })
        }

        l1countArr.map((i) => count = count + i.value)
        middlecountArr.map((i) => middleCount = middleCount + i.value)
        chart.tooltipText = count > 0 ? count : middleCount
      }
      else if (level2Data.length > 0 && level.level == 2) {
        let countIds = []
        sankeyData.map((k) =>
          level2Data.map((p) => {
            chart.links.each(function (link) {
              if (link.dataItem.dataContext.id == p.p_id) {
                link.isHover = true;
                if (!countIds.includes(link.dataItem.dataContext.id)) {
                  reverseCount = reverseCount + link.dataItem.dataContext.value
                  countIds.push(link.dataItem.dataContext.id)
                }
              }
              if (link.dataItem.dataContext.id == p.id) {
                link.isHover = true;
                if (!countIds.includes(link.dataItem.dataContext.id)) {
                  reverseCount = reverseCount + link.dataItem.dataContext.value
                  countIds.push(link.dataItem.dataContext.id)
                }
              }
            })
          })
        )

        l2countArr.map((i) => reverseCount = reverseCount + i.value)
        chart.tooltipText = reverseCount
        chart.tooltipPosition = "pointer"
      }
    });
    chart.nodes.template.events.on("out", function (event) {
      let dataItem = event.target.dataItem;
      let level = event.target.dataItem.dataContext
      let from = dataItem.dataContext.from
      let to = dataItem.dataContext.to
      let node_color = dataItem.dataContext.color

      let id = dataItem.dataContext.node_key.split("-")[0]
      let data = chart.data

      let filtereData = data.filter((item) => item.from === id)
      let count = 0
      let reverseCount = 0
      let middleCount = 0

      let middlelevelData = sankeyData.filter((item) => item.to == to)
      let level2Data = sankeyData.filter((item) => item.to == to)

      if (filtereData.length > 0 && level.level == 1) {
        if (!level1Color.includes(node_color) && !level3Color.includes(node_color)) {
          sankeyData.map((k) =>
            middlelevelData.map((p) => {
              chart.links.each(function (link) {
                link.isHover = false;
                count = 0
                middleCount = 0
              })
            })
          )
        }
        else {
          filtereData.map((sItem) => {
            if (sItem.to) {
              data.map((vItem) => {
                if (vItem.from == sItem.to) {
                  chart.links.each(function (link, idx) {
                    link.isHover = false;
                    count = 0
                    middleCount = 0
                  });
                }
              })
            }
          })
        }
      }
      else if (level2Data.length > 0 && level.level == 2) {
        sankeyData.map((k) =>
          level2Data.map((p) => {
            chart.links.each(function (link) {
              link.isHover = false;
              reverseCount = 0
            })
          })
        )
      }
    });
    let active = []
    sankeyData.map((sitem) => {
      active.push(sitem.from)
      active.push(sitem.to)
    })
    const unique_node = [];
    let node = active.length > 0 && active.filter(element => {
      const isDuplicate = unique_node.includes(element);
      if (!isDuplicate) {
        if (element != undefined) {
          unique_node.push(element);
        }
        return true;
      }
      return false;
    });
    let flag = 0

    chart.nodes.template.events.on("hit", function (event) {
      let data = event.target.dataItem.dataContext
      let current_node_to = event.target.dataItem.dataContext.to
      let current_node_from = event.target.dataItem.dataContext.from
      let current_node_color = event.target.dataItem.dataContext.color
      let level = event.target.dataItem.dataContext.level
      let p_id = event.target.dataItem.dataContext.p_id

      if (level == 2) {
        let inside_node = activeNodeLevel3
        if (!inside_node.includes(current_node_to)) {
          inside_node.push(current_node_to);
        } else {
          inside_node.splice(inside_node.indexOf(current_node_to), 1);
        }
        setactiveNodeLevel3(inside_node)

        let node_filter = sankeyData.filter((aitem) => aitem.to == current_node_to)
        node_filter.map((nItem) =>
          chart.links.each(function (link) {
            if (activeNodeLevel3.includes(nItem.to)) {
              if (link.dataItem.dataContext.to == nItem.from && link.dataItem.dataContext.node_key == nItem.node_key) {
                link._isHidden = true
                link.disabled = true
              }
            } else {
              if (link.dataItem.dataContext.to == nItem.from && link.dataItem.dataContext.node_key == nItem.node_key) {
                if (!activeNodeLevel1.includes(link.dataItem.dataContext.from)) {
                  console.log("link._isHidden", link.dataItem.dataContext.from, link.dataItem.dataContext.to)
                  link._isHidden = false
                  link.disabled = false
                }
              }
            }
          })
        )
      }
      else if (level == 1) {

        if (!level1Color.includes(current_node_color) && !level3Color.includes(current_node_color)) {

          let inside_node = []

          inside_node = activeNodeLevel2
          if (!inside_node.includes(current_node_to)) {
            inside_node.push(current_node_to);
          } else {
            inside_node.splice(inside_node.indexOf(current_node_to), 1);
          }
          setactiveNodeLevel2(inside_node)

          let node_filter = sankeyData.filter((aitem) => aitem.to == current_node_to || aitem.from == current_node_to)

          // node_filter.map((nItem) =>
          //   chart.links.each(function (link) {
          //     if (activeNodeLevel2.includes(nItem.to) || activeNodeLevel2.includes(nItem.from)) {
          //       console.log("first")
          //       if (link.dataItem.dataContext.to == nItem.to && link.dataItem.dataContext.from == nItem.from) {
          //         console.log("link-true-------------", link.dataItem.dataContext)
          //         link._isHidden = true
          //         link.disabled = true
          //       }
          //     } else {
          //       if (link.dataItem.dataContext.to == nItem.to && link.dataItem.dataContext.from == nItem.from) {
          //         console.log("link-false-------------", link.dataItem.dataContext)
          //         link._isHidden = false
          //         link.disabled = false
          //       }
          //     }
          //   })
          // )
        } else {
          let inside_node = activeNodeLevel1
          if (!inside_node.includes(current_node_from)) {
            inside_node.push(current_node_from);
          } else {
            inside_node.splice(inside_node.indexOf(current_node_from), 1);
          }
          setactiveNodeLevel1(inside_node)

          let filteredData = []

          let node_filter = sankeyData.filter((aitem) => aitem.from == current_node_from && aitem.id > 0)
          let subArr = []
          node_filter.map((left) => {
            sankeyData.map((sankey) => {
              if (sankey.p_id == left.id) {
                subArr.push(sankey)
              }
            })
          })

          // console.log("subArr", subArr)
          if (activeNodeLevel1.includes(current_node_from)) {
            subArr.map((nItem) =>
              chart.links.each(function (link) {
                if (link.dataItem.dataContext.id == nItem.id) {
                  link._isHidden = true
                  link.disabled = true
                }
              })
            )
          } else {
            subArr.map((nItem) =>
              chart.links.each(function (link) {
                if (link.dataItem.dataContext.id == nItem.id) {
                  link._isHidden = false
                  link.disabled = false
                }
              })
            )
          }
        }
      }
    })
  }
  const optimizedFn = useCallback(debounce(getSankeyData), [sankeyData]);
  return (
    <>
      <div className='' style={{ position: 'relative' }}>
        {
          isloading &&
          <div className="d-flex align-items-center justify-content-center" style={{ position: 'absolute', width: '100%', height: '100%', zIndex: '99999' }}>
            <Spinner animation="border" />
          </div>
        }
        <div className="d-flex align-items-center justify-content-between">
          <div className="p-2 bd-highlight d-flex align-items-center">
            <select onChange={handleMesele} style={{ width: 150 }} className="form-select select sankey_select me-2" aria-label="Default select example">
              <option value="">Select Mesele</option>
              <option value="1">Mesele 1</option>
              <option value="2">Mesele 2</option>
              <option value="3">Mesele 3</option>
              <option value="4">Mesele 4</option>
            </select>
            <DateRangePicker
              disabled={!activeChart}
              ranges={predefinedRanges}
              placeholder="Select Date"
              style={{ width: 220 }}
              value={dateRange}
              onChange={handleDateRange}
            />
          </div>
          <div className="p-2 bd-highlight">
            <div className="row_three d-flex align-items-center justify-content-end">
              <p onClick={() => activeChart && getViewList(0, "Event's List")} className="mb-0 inside_link" >View list</p>
            </div>
          </div>
        </div>

        <div style={{ position: 'relative', display: activeChart ? "none" : "grid", justifyContent: "center", }}>

          <div className='mt-4' >
            <h3 className='text-center'>Please Select Mesele</h3>
          </div>

          <div style={{ display: "flex", justifyContent: "center", marginTop: "20px" }}>
            <img style={{ width: "70%" }} src={dummyImage} />
          </div>
        </div>
        {
          !noData &&
          <div id='chartdiv' style={{ width: "100%", height: "500px", display: !activeChart ? "none" : "grid", }} className="pb-3 mb-3"></div>
        }
        {
          noData &&
          <div className="d-flex flex-column align-items-center justify-content-center" style={{ height: "70vh" }}>
            <img style={{ width: "40%", opacity: ".6" }} src={NoData} alt="" />
            <p className='noDataText'>No Data Found</p>
          </div>}

        <Modal
          show={show} onHide={() => handleCloseModal()}
          className="modal_xl"
          style={{ opacity: "1" }}
          dialogClassName="modal-90w"
          aria-labelledby="example-custom-modal-styling-title"
        >
          <Modal.Header >
            <div className="d-flex justify-content-between w-100">
              <p className="mb-0 modal_xl_header d-flex align-items-center justify-content-start">
                <span className="bullet_point me-2"></span>{heading}</p>
            </div>
            <div className="bg-info">
            </div>
            <Modal.Header closeButton></Modal.Header>
          </Modal.Header>
          <Modal.Body>
            <React.Fragment>
              <div className="row m-0">
                <div className="col-12 p-0">
                  <p className="mb-0 xl_inside_heading"></p>
                </div>
              </div>
              <div className="row m-0 mt-2">
                <div className="col-12 p-0 position-relative">
                  <table
                    className="table tbl_mrg_btm scrollbody"
                    id="main-table"
                  >
                    <thead>
                      <tr>
                        <th className="table_head tb_head_one text-center" scope="col">
                          <div className='tb_head_one_inner'>
                            #
                          </div>
                        </th>
                        <th className="table_head tb_head_two" scope="col">Subject</th>
                        <th className="table_head tb_head_three" scope="col">Internal Note</th>
                        <th className="table_head tb_head_three tb_head_three_sm " scope="col">From</th>
                        <th className="table_head tb_head_three tb_head_three_sm" scope="col">To</th>
                        <th className="table_head tb_head_four" scope="col">Doc Type</th>
                        <th className="table_head tb_head_four" scope="col">Folder Type</th>
                        <th className="table_head tb_head_four" scope="col">Note Count </th>
                      </tr>
                    </thead>
                    <tbody className=""
                      id="main-div"
                      ref={listInnerRef}
                      onScroll={(e) => handleScroll()}
                    >
                      {
                        viewList.map((item, index) =>
                          <tr className="table_row">
                            <th className="tb_data tb_head_one text-center" scope="row">
                              <div className='tb_head_one_inner'>
                                {
                                  item.group_name == "Document" ?
                                    <img src={AddFile} />
                                    :
                                    item.group_name == "E-Mail" ?
                                      <img src={EmailgreenIcon} width="16" />
                                      :
                                      item.group_name == "Miscellaneous" ?
                                        <img src={menu} />
                                        :
                                        ""
                                }
                              </div>
                            </th>
                            <td className="tb_data tb_head_two"><div className="cnt" title={item.subject_text}>{item.subject_text}</div></td>
                            <td className="tb_data tb_head_three"><div className="cnt" title={item.internal_note}>{item.internal_note}</div></td>
                            <td className="tb_data tb_head_three tb_head_three_sm">{item.from_user}</td>
                            <td className="tb_data tb_head_three tb_head_three_sm">{item.to_user}</td>
                            <td className="tb_data tb_head_four">{item.group_name}</td>
                            <td className="tb_data tb_head_four">{item.folder_type}</td>
                            <td className="tb_data tb_head_four"></td>
                          </tr>
                        )
                      }
                    </tbody>
                  </table>
                </div>
              </div>
              {isloading &&
                <div className="loader_sp"><div className="spinner">
                  <Spinner animation="border" />
                </div></div>}
            </React.Fragment>
          </Modal.Body>
        </Modal>
      </div>
    </>
  )
}