// import React, { Component, useState } from 'react'
// import { Redirect } from 'react-router-dom'
// import { withRouter } from 'react-router-dom';

// export default function ChartsDropdown(props) {
//     const [activeGraph, setActiveGraph] = useState("")
//     const [redirect, setRedirect] = useState(false)

//     function handlegraph(route) {
//         console.log("route", route.target.value)
//         setActiveGraph(route)
//         setRedirect(true)
//     }

//     return (
//         <>
//             <select onChange={handlegraph} className="form-select select" aria-label="Default select example">
//                 <option selected >Data Visualization</option>
//                 <option disabled={props.match.path == "/"} value="">Snake Timeline</option>
//                 <option disabled={props.match.path == "/tree-map"} value="tree-map">Tree Map</option>
//                 <option disabled={props.match.path == "/word-cloud"} value="word-cloud">Word Cloud</option>
//                 <option disabled={props.match.path == "/vertical-line"} value="vertical-line">Vertical Line Chart</option>
//                 <option disabled={props.match.path == "/sankey"} value="sankey">Sankey Chart</option>
//             </select>
//         </>
//     )
// }

import React, { Component } from 'react'
import { Redirect } from 'react-router-dom'
import { withRouter } from 'react-router-dom';

class ChartsDropdown extends Component {
    constructor(props) {
        super(props);
        this.state = {
            activeGraph: "",
            redirect: false
        }
    }
    handlegraph(route) {
        this.setState({
            activeGraph: route.target.value,
            redirect: true
        })
    }
    componentDidMount() {
    }
    render() {
        if (this.state.redirect && "/" + this.state.activeGraph != this.props.match.path) {
            return <Redirect to={"/" + this.state.activeGraph} />
        }
        return (
            <>
                <select onChange={this.handlegraph.bind(this)} className="form-select select" aria-label="Default select example">
                    <option  >Data Visualization</option>
                    <option disabled={this.props.match.path == "/"} value="">Snake Timeline</option>
                    <option disabled={this.props.match.path == "/tree-map"} value="tree-map">Tree Map</option>
                    <option disabled={this.props.match.path == "/word-cloud"} value="word-cloud">Word Cloud</option>
                    <option disabled={this.props.match.path == "/vertical-line"} value="vertical-line">Vertical Line Chart</option>
                    <option disabled={this.props.match.path == "/sankey"} value="sankey">Sankey Chart</option>
                </select>
            </>
        )
    }
}
export default withRouter(ChartsDropdown);