import React, { useState, useEffect, useRef, useCallback } from "react";
import axios from 'axios'
import { Link } from 'react-router-dom'
import { APIURL } from "./../../Constant/config"
import { Zoomscale } from "../../Constant/ZoomScale";
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import DocumentIcon from './../../assets/document.png'
import EmailIcon from './../../assets/mail_green.png'
import MiscIcon from './../../assets/menu.png'
import RightIcon from './../../assets/RightIcon.png'
import Spinner from 'react-bootstrap/Spinner';
//import $ from 'jquery';
import dateFormat from "dateformat";
import useDynamicRefs from 'use-dynamic-refs';


let timer = 0;
let delay = 400;
let prevent = false;

export default function VerticalLine(props) {
    let ref = React.useRef(null)
    const listInnerRef = useRef()
    const [getRef, setRef] = useDynamicRefs();
    const monthNames = [" ", "Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"]
    const [scrollCheck, setscrollCheck] = useState(0)
    const [scrollStatus, setscrollStatus] = useState(true)
    const [verticalData, setverticalData] = useState([])
    const [noOfRecords, setnoOfRecords] = useState()
    const [rightSet, setrightSet] = useState([])
    const [scaleValue, setscaleValue] = useState(1)
    const [selectYearMonth, setselectYearMonth] = useState(dateFormat(new Date(), "yyyy-mm"))
    const [dateFont, setdateFont] = useState(15)
    const [inView, setInView] = useState([])
    const [zoomClass, setZoomClass] = useState("zoomOne")
    const [activeScroll, setActiveScroll] = useState(false)
    const [content, setContent] = useState([])
    const [rowData, setrowData] = useState([])
    const [viewportMaxid, setviewportMaxid] = useState("")
    const [phaseData, setphaseData] = useState([])
    const [isloading, setisloading] = useState(false)
    const [accessApi, setaccessApi] = useState(true)
    const [show, setshow] = useState(false)
    const [doubleClickId, setdoubleClickId] = useState("")
    const [doubleClick, setdoubleClick] = useState(false)
    const [eventType, seteventType] = useState("initial")
    const [scroll, setscroll] = useState(false)
    const [activeArrowUp, setactiveArrowUp] = useState(false)
    const [activeArrowDown, setactiveArrowDown] = useState(false)
    const [noData, setNoData] = useState(false)
    const [dataCount, setdataCount] = useState(800)
    const yearStatusInnerRef = useRef(null);
    //const box = document.querySelector('.box');
    const zoom = [
        { id: 1, className: "zoomOne" },
        { id: 2, className: "zoomTwo" },
        { id: 3, className: "zoomThree" },
        { id: 4, className: "zoomFour" },
        { id: 5, className: "zoomFive" },
        { id: 6, className: "zoomSix" },
        { id: 7, className: "zoomSeven" },
    ]

    useEffect(() => {
        let event_type = ""
        document.documentElement.style.setProperty('--scale_value', `${scaleValue}`)

        if (props.data.activeFilter) {
            event_type = "filter"
            optimizedFn(selectYearMonth, "", "filter")
            // getMonthData(selectYearMonth, "", "filter")
            setselectYearMonth(selectYearMonth)
        }
        if (doubleClick) {
            event_type = "zoom"
        }
        else if (!props.data.activeFilter) {
            event_type = "initial"
        }
        if (accessApi && props.data.refresh && !props.data.activeFilter) {
            // optimizedFn(selectYearMonth, "", event_type)
            getMonthData(selectYearMonth, "", event_type)
            setselectYearMonth(selectYearMonth)
        }
        if (rowData.length == 0) {
            setisloading(false)
        }

    }, [props.data])

    useEffect(() => {
        if (rowData.length != []) {
            let data = rowData
            let uniqueIdss = []
            let viewPortTopId = viewportMaxid
            let Filterdata = []

            let uniqueMonth = data.length > 0 && data.filter(element => {
                const isDuplicate = uniqueIdss.includes(element.date.split('-')[0] + "-" + element.date.split('-')[1]);
                if (!isDuplicate) {
                    uniqueIdss.push(element.date.split('-')[0] + "-" + element.date.split('-')[1]);
                    return true;
                }
                return false;
            });

            phaseData.map((yitem, idx) =>
                phaseData[idx].row_change = yitem.phase_count <= 15 ? true : false
            )
            let year_month = phaseData.filter((item) => uniqueIdss.includes(item.date_time_year))
            // name(year_month)
            setverticalData(year_month)

            if (scroll) {
                console.log("scrollllllllllllll")
                setTimeout(() => {
                    viewPortUpdate()
                    setTimeout(() => {
                        setisloading(false)
                        setActiveScroll(false)
                    }, 500);
                }, 3000);
            } else {
                setisloading(false)
            }
        }
        if (rowData.length == 0) {
            setisloading(false)
        }
        setTimeout(() => {
            ViewPortCheck("props")
        }, 200);
    }, [rowData, phaseData])

    useEffect(() => {
        document.documentElement.style.setProperty('--scale_value', `${scaleValue}`);
        setscaleValue(scaleValue)
        zoom.map(async (i) => {
            if (i.id == scaleValue) {
                await setZoomClass(i.className)
                //setTimeout(() => {
                    if (viewportMaxid != "") {
                        let getRefVal =getRef("qubeRef"+viewportMaxid)
                        if(getRefVal!=undefined && getRefVal.current!=undefined)
                        {
                            getRefVal.current.scrollIntoView();
                            ViewPortCheck("viewportMaxid viewcheck")
                            
                        }
                        else
                        {
                            ViewPortCheck("viewportMaxid viewcheck")
                            console.log("viewportMaxid undefined");
                        }
                       
                        setisloading(false)
                    }else{
                        setisloading(false)
                    }
                //}, 2000);
            }
        })
    }, [scaleValue])

    const debounce = (func) => {
        let timer;
        return function (...args) {
            const context = this;
            if (timer) clearTimeout(timer);
            timer = setTimeout(() => {
                timer = null;
                func.apply(context, args);
            }, 2000);
        };
    };

    useEffect(() => {
        if (inView.length > 0 && inView != []) {
            monthScroll(inView)
        }
    }, [inView]);

    function viewPortUpdate() {

        var flag = 0;
        let Filterdata = rowData.filter((vItem) => vItem.id <= viewportMaxid)
        Filterdata.map((item) => {
            if (getRef("qubeRef"+item.id)!=undefined && getRef("qubeRef"+item.id).current!=undefined && flag == 0) {
                getRef("qubeRef"+item.id).current.scrollIntoView();
                setscrollStatus(true);
                flag = 1;
            }
            else if (flag == 0)
            {
                setscrollStatus(true);
            }
         
        })
    }
    function prepareStartEndData(data, lastMonth, direction) {
        let count = 0
        let flag = 0
        let months = []
        let paramDates = {}
        let records = ""
        let recordsCount = ""

        Zoomscale.map((z) => {
            if (z.scale == scaleValue) {
                setnoOfRecords(z.count)
                records = z.count
            }
        })
        recordsCount = direction != "" ? records : dataCount
        console.log("recordsCount", recordsCount)

        if (direction == "down" || direction == "") {
            data.filter((d) => d.date_time_year < lastMonth).map((item) => {
                if (flag == 0) {
                    count = count + item.phase_count
                    months.push(item.date_time_year)
                    if (count >= recordsCount) {
                        flag = 1
                    }
                }
            })
            paramDates = {
                start_Date: months[months.length - 1] + "-01",
                end_Date: dateFormat(new Date(months[0].split('-')[0], months[0].split('-')[1], 0), "yyyy-mm-dd"),
            }
        } else if (direction == "up") {
            let news = data.filter((d) => d.date_time_year > lastMonth)

            data.filter((d) => d.date_time_year > lastMonth).reverse().map((item) => {
                if (flag == 0) {
                    count = count + item.phase_count
                    months.push(item.date_time_year)
                    if (count >= recordsCount) {
                        flag = 1
                    }
                }
            })
            paramDates = {
                start_Date: months[0] + "-01",
                end_Date: dateFormat(new Date(months[months.length - 1].split('-')[0], months[months.length - 1].split('-')[1], 0), "yyyy-mm-dd"),
            }
        }
        return paramDates
    }
    const getMonthData = (year_month, direction, type_of_event) => {
        setisloading(true)
        let direction_flow = ""

        if (type_of_event == "initial") {
        }
        if (type_of_event == "zoom" && direction == "") {
        }
        if (type_of_event == "filter") {
        }
        if (type_of_event == "scroll") {
            direction_flow = direction
        }
        let monthParams = {
            group_ids: props.data.activeGroupIds,
            filterValues: props.data.filters,
        }

        let currentMonth = dateFormat(new Date(), "yyyy-mm")
        if(selectYearMonth!="" && type_of_event=="filter")
        {
            currentMonth=selectYearMonth
        }
        getData(monthParams, currentMonth, direction_flow)
    }
    function getData(monthParams, currentMonth, direction) {
        axios.get(APIURL + '/api/tutorials/vertical-timelinemonth1', { params: monthParams })
            .then(async resp => {
                let res = resp.data
                if (res.length > 0) {
                    getYearMonth(res)
                    setphaseData(res)
                    let dateRecord = await prepareStartEndData(res, currentMonth, direction)
                    console.log("dateRecord", dateRecord)
                    getVerticalData(dateRecord, direction)
                } else {
                    setisloading(false)
                    getYearMonth(res)
                    setphaseData(res)
                }
            })
            .catch(error => {
                console.log(error);
            });
    }
    function getVerticalData(dateParam, direction) {
        setisloading(true)
        let verticalParams = {
            start_date: dateFormat(dateParam.start_Date, "yyyy-mm-dd"),
            end_date: dateFormat(dateParam.end_Date, "yyyy-mm-dd"),
            group_ids: props.data.activeGroupIds,
            filterValues: props.data.filters,
        }
        axios.get(APIURL + '/api/tutorials/vertical-timeline1', { params: verticalParams })
            .then((resp) => {
                let res = resp.data
                setNoData(true)
                if (res.length > 0) {
                    appendPrependRemoveData(res, direction)
                } else {
                    setisloading(false)
                    if (rowData.length == 0) {
                        setrowData([]);
                    }
                }
            })
            .catch(error => {
                console.log(error);
            });
    }
    function getYearMonth(data) {
        const uniqueIds = [];
        const unique = data.filter(element => {
            const isDuplicate = uniqueIds.includes(element.date_time_year);
            if (!isDuplicate) {
                uniqueIds.push(element.date_time_year);
                return true;
            }
            return false;
        });

        let year_month = []
        unique.map((i) => year_month.push(i.date_time_year))

        let newData = []
        let filterData = []
        let filterPhase = []

        year_month.map((sItem) => {
            filterData = data.filter((vItem) => vItem.date_time_year == sItem);
            newData.push({
                month: sItem,
            })
        })

        let rec = []
        newData.map((s) => rec.push(s.month.split("-")[0]))
        const uniqueY = rec.filter(element => {
            const isDuplicate = uniqueIds.includes(element);
            if (!isDuplicate) {
                uniqueIds.push(element);
                return true;
            }
            return false;
        });

        let resp = []
        let mon = []
        uniqueY.map((o) => {
            let fill = year_month.filter((p) => p.split("-")[0] == o)
            resp.push({
                year: o,
                months: fill.map((x) => x.split("-")[1] <= 9 ? x.split("-")[1] : x.split("-")[1]),
            })
        })
        setrightSet(resp)
    }
    // Zoom management 
    const updateScale = (zoomType) => {
        setisloading(true)
        // setaccessApi(false)
        // setscrollStatus(false)
        setTimeout(function(){
            if (zoomType == "") {
                setscaleValue(1)
            } else if (zoomType == "zoomin") {
                setscaleValue(scaleValue + 1)
                setdateFont(dateFont - 2)
            }
            else if (zoomType == "zoomout") {
                setscaleValue(scaleValue - 1)
                setdateFont(dateFont + 2)
            }
            else if (zoomType == "zoomCustom") {
                setscaleValue(7)
            }
        },3000);
        if (!doubleClick) {
            getFirstElement()
        }
        // zoom.map((i) => { if (i.id == scaleValue) { setZoomClass(i.className) } })

    }

    function appendPrependRemoveData(arr, direction) {
        // if (arr.length == 0) {
        //     setrowData([]);
        //     return false
        // }
        arr.map((item)=>{
            arr.visible=false
        })
        let count = 0
        let flag = 0
        let months = []
        if (direction == "down") {
            verticalData.map((item) => {
                if (flag == 0) {
                    count = count + item.phase_count

                    months.push(item.date_time_year)
                    if (count >= arr.length) {
                        flag = 1
                    }
                }
            })
        } else if (direction == "up") {
            verticalData.reverse().map((item) => {
                if (flag == 0) {
                    count = count + item.phase_count
                    months.push(item.date_time_year)
                    if (count >= arr.length) {
                        flag = 1
                    }
                }
            })
        }

        if (direction == "") {
            arr=[...arr]
        }
        else if (direction == "down") {

            let record = rowData.filter((i) => i.date <= months[months.length - 1])
            console.log("down",record.length,arr.length)
            arr=[...record, ...arr]
            console.log("down 2",arr.length)

        }
        else if (direction == "up") {
            let record = rowData.filter((i) => dateFormat(i.date, "yyyy-mm") > months[months.length - 1])
            arr=[...arr, ...record]
        }
        arr.map((item,index)=>{
            item.index=index
        })

        setrowData(arr)
    }

    const handleScroll = async () => {
        console.log("scrollStatus sssss")
        if (scrollStatus == true && !isloading) {
            console.log("scrollStatus true")
            if (scrollCheck > listInnerRef.current.scrollTop) {
                console.log("scrollStatus if")
                if (listInnerRef.current.scrollTop == 0) {
                    if (dateFormat(rowData[0].date, "yyyy-mm") == phaseData[0].date_time_year) {
                        console.log("down sssss")
                        return false;
                    }
                    // setaccessApi(true)
                    getFirstElement()
                    setisloading(true)
                    setscroll(true)
                    setActiveScroll(true)
                    setscrollStatus(false)
                    setTimeout(async function(){
                        //setActiveScroll(true)
                        //setscroll(true)
                        //setscrollStatus(false)
                        let lastMonth = rowData[0].date.split('-')[0] + "-" + rowData[0].date.split('-')[1]
                        let dateRecord = await prepareStartEndData(phaseData, lastMonth, "up")
                        getVerticalData(dateRecord, "up")
                    },1000);
                }
            }
            else {
                console.log("scrollStatus else")
                if (listInnerRef.current) {
                    console.log("scrollStatus else if")
                    const { scrollTop, scrollHeight, clientHeight } = listInnerRef.current;
                    let scrollBottom = scrollHeight
                    if (scrollTop + clientHeight >= scrollBottom) {
                        if (dateFormat(rowData[rowData.length - 1].date, "yyyy-mm") == phaseData[phaseData.length - 1].date_time_year) {
                            console.log("up sssss")
                            return false;
                        }
                        getFirstElement()
                        setisloading(true)
                        setActiveScroll(true)
                        setscroll(true)
                        setscrollStatus(false)

                        setTimeout(async function(){
                            //setActiveScroll(true)
                            //setscroll(true)
                            //setscrollStatus(false)
                            let lastMonth = rowData[rowData.length - 1].date.split('-')[0] + "-" + rowData[rowData.length - 1].date.split('-')[1]
                            let dateRecord = await prepareStartEndData(phaseData, lastMonth, "down")
                            getVerticalData(dateRecord, "down")
                        },1000);
                    }
                }
            }
            setscrollCheck(listInnerRef.current.scrollTop)
        }
        setTimeout(() => {
            ViewPortCheck("scroll")
        }, 200);
        if (show) {
            var element = document.getElementById("popup");
            //element.classList.remove("opacity_y");
            setshow(false)
            return false
        }
        if (show) {
            setshow(false)
        }
    }

    function handleRedirect(url) {
        window.open(`${url}`, "_blank")
    }
    // view port event start 
    function ViewPortCheck(from) {
        console.log("ViewPortCheck",from,rowData.length);
        let view = []

        rowData.map((item)=>{
            lazyInit("qubeRef"+item.id,'0px 0px 0px 0px');
        })
        setTimeout(function(){
            var inView=rowData.filter((item)=> item.visible==true);
            inView.sort((a, b) => (a.index > b.index) ? 1 : -1)
            inView.map((item)=>{
                setselectYearMonth(item.date.split('-')[0] + "-" + item.date.split('-')[1])
                view.push(item.date.split('-')[0] + "-" + item.date.split('-')[1]);
            })

            console.log("view",...new Set(view));

            setInView([...new Set(view)])
        },500)
       
    }


    const callbackf =  (entries,options) => {
        const [intry] =entries    
        rowData[intry.target.dataset.index].visible=intry.isIntersecting
    }

    const lazyInit =  (refId,rootMargin) => {
       const observer = new IntersectionObserver(callbackf,{rootMargin:rootMargin})
       const getRefVal=getRef(refId);
       if(getRefVal!=undefined)
       {
          if(getRefVal.current!=null)
          {
            observer.observe(getRefVal.current)
          }
       }
    }


    function getFirstElement() {
        let count = 0
        let max_id = ""
        if (!doubleClick) {
            rowData.map((item)=>{
                lazyInit("qubeRef"+item.id,'0px 0px 0px 0px');
            })
            setTimeout(function(){
                var inView=rowData.filter((item)=> item.visible==true);
                inView.sort((a, b) => (a.index > b.index) ? 1 : -1)
                if(inView.length){
                    setviewportMaxid(inView[0].id)
                }
            },1000)
        }
    }

    // month event 
    async function handleMonthYearData(year, month) {
        seteventType("initial")
        setInView(year + "-" + month)
        let dateRecord = await prepareStartEndData(phaseData, year + "-" + month + 1, "")
        getVerticalData(dateRecord, "")
        setselectYearMonth(year + "-" + month, "")
        listInnerRef.current.scrollTop = 10
    }
    const handleYearScroll = () => {
        if (yearStatusInnerRef.current.scrollTop < 15) {
            setactiveArrowUp(false)
        }
        else {
            setactiveArrowUp(true)
        }

        if (yearStatusInnerRef.current.scrollHeight <= (15 + yearStatusInnerRef.current.offsetHeight + yearStatusInnerRef.current.scrollTop)) {
            setactiveArrowDown(false)
        }
        else {
            setactiveArrowDown(true)
        }
    }
    // month event end 
    //click events start
    const doClickAction = (id) => {
        setaccessApi(false)
        axios.get(APIURL + '/api/tutorials/vertical-timeline-by-id/' + id)
            .then((resp) => {
                setContent(resp.data)
                setaccessApi(true)
            })
            .catch(error => {
                console.log(error);
            });
    }

    const doDoubleClickAction = () => {
        // updateScale("zoomCustom")
        setscaleValue(7)
        setaccessApi(true)
        setshow(false)
    }
    const handleClick = (id) => {
        setshow(true)
        setaccessApi(false)
        timer = setTimeout(function () {
            if (!prevent) {
                doClickAction(id)
            }
            prevent = false;
        }, delay);
    }
    const handleDoubleClick = (id) => {
        setdoubleClickId(id)
        setviewportMaxid(id)
        setdoubleClick(true)
        clearTimeout(timer)
        prevent = true;
        doDoubleClickAction()
    }
    const optimizedFn = useCallback(debounce(getMonthData), [props.data]);

    function monthScroll(years) {
        if (years.length > 0 && years.length != []) {
            let scrollId = years[0].split('-')[0]
            let getRefVal =getRef("yearRef"+scrollId)
            if(getRefVal!=undefined && getRefVal.current!=undefined)
            {
                getRefVal.current.scrollIntoView();
            }
            else
            {
                console.log("monthScroll undefined");
                
            }
           
            /*setTimeout(() => {
                getRef("yearRef"+scrollId).current.scrollIntoView();
            }, 200);*/
        }
    }
    //click events end

    return (
        <div className="yearStatusParent" style={{ position: "relative" }}>
            {
                isloading &&
                <div className="d-flex align-items-center justify-content-center loderBlur" style={{ position: 'absolute', width: '100%', height: '100%', zIndex: '9999' }}>
                    <Spinner animation="border" />
                </div>
            }
            <div className="tools d-flex flex-column" >
                <button disabled={scaleValue == 7} className={scaleValue == 7 ? "btn btn-dark navigation_btns navigation_btns_dsbl mt-1 " : "btn btn-dark navigation_btns mt-1"} onClick={() => updateScale("zoomin")} data-bs-toggle="tooltip" data-bs-placement="right" title="Zoom In"> <i className="ri-zoom-in-line"></i> </button> {/* */}
                <button disabled={scaleValue == 1} className={scaleValue == 1 ? "btn btn-dark navigation_btns navigation_btns_dsbl mt-1 " : "btn btn-dark navigation_btns mt-1"} onClick={() => updateScale("zoomout")} data-bs-toggle="tooltip" data-bs-placement="right" title="Zoom Out"> <i className="ri-zoom-out-line"></i> </button> {/* */}
                <button className="btn btn-dark navigation_btns mt-1" onClick={() => scaleValue != 7 && updateScale("zoomCustom")} data-bs-toggle="tooltip" data-bs-placement="right" title="Max Zoom"> <i className="ri-fullscreen-line"></i> </button> {/* <img src={Maximize} alt="" /> <i className="ri-fullscreen-line"></i> */}
                <button className="btn btn-dark navigation_btns mt-1" onClick={() => scaleValue != 1 && updateScale("")} data-bs-toggle="tooltip" data-bs-placement="right" title="Reset Zoom"> <i className="ri-refresh-line"></i> </button>
            </div>

            <div
                className={!activeScroll ? "lineChartAreaParent lineChartAreaParentOverFlow" : "lineChartAreaParent lineChartAreaParentOverFlowHidden"}
                ref={listInnerRef}
                onScroll={() => handleScroll()}
            >
                {
                    scaleValue <= 5 &&
                    rightSet.length !== 0 && noData == true && <div className={"linechartArea " + zoomClass}>

                        <div className="" style={{ height: "75px" }}></div>

                        {
                            verticalData.length > 0 && verticalData.map((item, idx) =>
                                <React.Fragment key={idx}>
                                    {
                                        (idx > 0 && ((parseInt(verticalData[idx - 1].date_time_year.split("-")[1])) == 1 ? (parseInt(item.date_time_year.split("-")[1]) != 12) : (parseInt(item.date_time_year.split("-")[1]) + 1) != (parseInt(verticalData[idx - 1].date_time_year.split("-")[1]))))
                                        && <div className={item.row_change ? (idx % 2) ? "monthActivity monthActivityMargBtm leftLine" : "monthActivity monthActivityMargBtm rightLine" : "monthActivity " + (idx % 2 ? 'leftLine blankMonthActivity_month' : 'rightLine blankMonthActivity_month')} >

                                            <div className="monthActivity_month">
                                            </div>
                                            <ul className={(idx > 0 && (parseInt(item.date_time_year.split("-")[1]) + 1) != (parseInt(verticalData[idx - 1].date_time_year.split("-")[1]))) ? "monthActivityList rec_data mrg_tp" : "monthActivityList rec_data"}>
                                                <li>&nbsp;</li>
                                            </ul>
                                        </div>
                                    }

                                    <div className={item.row_change ? (idx % 2) ? "monthActivity monthActivityMargBtm rightLine" : "monthActivity monthActivityMargBtm leftLine" : "monthActivity " + (idx % 2 ? 'rightLine' : 'leftLine')}>

                                        <div className="monthActivity_month"  >
                                            <div className="fixedElement">
                                                <h6 >{monthNames[parseInt(item.date_time_year.split("-")[1])] + ", " + item.date_time_year.split("-")[0]}</h6>

                                                <div className="activityNum">
                                                    {item.phase_count + " "}Phase
                                                </div>
                                            </div>
                                        </div>
                                        <ul className={(idx > 0 && (parseInt(item.date_time_year.split("-")[1]) + 1) != (parseInt(verticalData[idx - 1].date_time_year.split("-")[1]))) ? "monthActivityList rec_data mrg_tp" : "monthActivityList rec_data"}>
                                            {
                                                rowData.length > 0 && rowData.map((sItem, idxs) =>
                                                    sItem.date.split('-')[0] + "-" + sItem.date.split('-')[1] == item.date_time_year &&
                                                    <React.Fragment key={idxs}>
                                                        <OverlayTrigger
                                                            ref={r => (ref = r)}
                                                            trigger="legency"
                                                            placement="left"
                                                            rootClose="true"
                                                            overlay={
                                                                <div {...props}  id="popup" className="opacity_y toDoBox toDoBoxLevelSeven toDoBoxShadow p-2">
                                                                    <div className="toDoBoxLevelBoxIcon d-flex align-items-center">
                                                                        <div style={{ width: "20px", height: "20px" }} className={content.group_id == 1 ? "orange_box d-flex align-items-center justify-content-center " : content.group_id == 2 ? "green_box d-flex align-items-center justify-content-center " : "gray_box d-flex align-items-center justify-content-center " + "icon_box orange_box p-1 d-flex align-items-center justify-content-center "}>
                                                                            <img style={{ width: "90%" }} src={content.group_id == 1 ? DocumentIcon : content.group_id == 2 ? EmailIcon : MiscIcon} alt="" />
                                                                        </div>
                                                                    </div>
                                                                    <div className=" pe-1 mt-2">
                                                                        <p className="mb-0 userName">Subject</p>
                                                                        <p className="mb-0 userComments mt-1">{content.subject_text}</p>

                                                                        <p className="mb-0 userName mt-1">Internal Notes</p>
                                                                        <p className="mb-0 userComments mt-1">{content.internal_note}</p>


                                                                        <p className="mb-0 userName mt-1">From </p>
                                                                        <p className="mb-0 userComments mt-1">{content.from_user}</p>


                                                                        <p className="mb-0 userName mt-1">To </p>
                                                                        <p className="mb-0 userComments mt-1">{content.to_user}</p>
                                                                    </div>

                                                                    <div className=" d-flex align-items-center justify-content-end">
                                                                        <img onClick={(e) => handleRedirect(content.link)} style={{ height: 30, opacity: .7, cursor: "pointer" }} src={RightIcon} alt="" />
                                                                    </div>
                                                                </div>
                                                            }
                                                        >
                                                            {
                                                                scaleValue <= 5 &&
                                                                <li  ref={setRef("qubeRef"+sItem.id)} data-index={idxs}>
                                                                    <Link to="#" onClick={(e) => handleClick(sItem.id)} onDoubleClick={(e) => handleDoubleClick(sItem.id)} className={sItem.group_id == 1 ? "document" : sItem.group_id == 2 ? "email" : "misc"} ></Link>
                                                                </li>
                                                            }
                                                        </OverlayTrigger>
                                                    </React.Fragment>
                                                )
                                            }
                                        </ul>
                                    </div>

                                </React.Fragment>
                            )
                        }

                        <div className="" style={{ height: "75px" }}></div>


                    </div>
                }
                {/* 6th Level Cards */}
                {
                    scaleValue == 6 && rightSet.length != 0 &&
                    <div className="row m-0 d-flex align-items-center justify-content-center">

                        {/* <div className="col-2 p-0"></div> */}

                        <div className="col-8 p-0">

                            {rowData.length != 0 && noData != true && <div className="" style={{ height: "50px" }}></div>}

                            <div className="toDoBoxParent d-flex flex-wrap ps-4">
                                {
                                    // verticalData.length > 0 && verticalData.map((vItem,index) =>
                                    rowData.length > 0 && rowData.map((DItem, idxx) =>
                                        <React.Fragment key={idxx}>
                                            <div ref={setRef("qubeRef"+DItem.id)} data-index={idxx}  className="toDoBox toDoBoxLevelSix px-2 pt-2 pb-1 me-2 my-1">

                                                <div className="d-flex align-items-center">

                                                    <div className={DItem.group_id == 1 ? "orange_box d-flex align-items-center justify-content-center" : DItem.group_id == 2 ? "green_box d-flex align-items-center justify-content-center" : "gray_box d-flex align-items-center justify-content-center" + "icon_box p-1 d-flex align-items-center justify-content-center"}>
                                                        <img style={{ width: "70%" }} src={DItem.group_id == 1 ? DocumentIcon : DItem.group_id == 2 ? EmailIcon : MiscIcon} alt="" />
                                                    </div>

                                                    <p className="date_heading mb-0 ms-2">{dateFormat(DItem.date, "dd") + ", " + monthNames[parseInt(DItem.date.split("-")[1])]}</p>
                                                </div>

                                                <div className="toolBox_users_convo pe-1 mt-2">
                                                    <p className="mb-0 userName">Subject</p>
                                                    <p className="mb-0 userComments mt-1">{DItem.subject_text}</p>

                                                    <p className="mb-0 userName mt-2">Internal Notes</p>
                                                    <p className="mb-0 userComments mt-1">{DItem.internal_note}</p>
                                                </div>

                                                <div className=" d-flex align-items-center justify-content-end">
                                                    <img onClick={(e) => handleRedirect(DItem.link)} style={{ height: 30, opacity: .7, cursor: "pointer" }} src={RightIcon} alt="" />
                                                </div>

                                            </div>
                                        </React.Fragment>
                                        // )
                                    )
                                }
                            </div>

                            {rowData.length != 0 && noData != true && <div className="" style={{ height: "100px" }}></div>}

                        </div>

                        {/* {
                            rowData.length == 0 && noData == true &&
                            <div style={{ width: "100%", height: "70vh" }} class="d-flex flex-column align-items-center justify-content-center">
                                <p style={{ zIndex: 10 }} className='noDataText'>No Data Found</p>
                            </div>
                        } */}

                    </div>
                }

                {/* 6th Level Cards */}
                {/* 7th Level Cards */}

                {
                    scaleValue == 7 && rightSet.length != 0 &&
                    <div className="row m-0 px-5 d-flex align-items-center justify-content-center">

                        <div className="col-11 p-0">

                            {rowData.length != 0 && noData != true && <div className="" style={{ height: "75px" }}></div>}

                            <div className="toDoBoxParent d-flex flex-wrap ps-4">

                                {
                                    rowData.length > 0 && rowData.map((DItem, indx) =>
                                        <div ref={setRef("qubeRef"+DItem.id)} data-index={indx} key={indx}  className="toDoBox toDoBoxLevelSeven px-2 pt-2 pb-1 me-2 my-1">

                                            <div className="d-flex align-items-center">

                                                <div className={DItem.group_id == 1 ? "orange_box d-flex align-items-center justify-content-center" : DItem.group_id == 2 ? "green_box d-flex align-items-center justify-content-center" : "gray_box d-flex align-items-center justify-content-center" + "icon_box p-1 d-flex align-items-center justify-content-center"}>
                                                    <img style={{ width: "70%" }} src={DItem.group_id == 1 ? DocumentIcon : DItem.group_id == 2 ? EmailIcon : MiscIcon} alt="" />
                                                </div>
                                                <p className="date_heading mb-0 ms-2"> {dateFormat(DItem.date, "dd") + ", " + monthNames[parseInt(DItem.date.split("-")[1])] + ", " + DItem.date.split("-")[0]}</p>
                                            </div>

                                            <div className="toolBox_users_convo pe-1 mt-3">
                                                <p className="mb-0 userName">Subject</p>
                                                <p className="mb-0 userComments mt-0">{DItem.subject_text}</p>

                                                <p className="mb-0 userName mt-1">Internal Notes</p>
                                                <p className="mb-0 userComments mt-0">{DItem.internal_note}</p>


                                                <p className="mb-0 userName mt-1">From </p>
                                                <p className="mb-0 userComments mt-0">{DItem.from_user}</p>


                                                <p className="mb-0 userName mt-1">To </p>
                                                <p className="mb-0 userComments mt-0">{DItem.to_user}</p>
                                            </div>

                                            <div className=" d-flex align-items-center justify-content-end">
                                                <img onClick={(e) => handleRedirect(DItem.link)} style={{ height: 30, opacity: .7, cursor: "pointer" }} src={RightIcon} alt="" />
                                            </div>
                                        </div>
                                    )
                                }
                            </div>

                            {rowData.length != 0 && noData != true && <div className="" style={{ height: "100px" }}></div>}

                            {/* {
                                rowData.length == 0 && noData == true &&
                                <div style={{ width: "100%", height: "70vh" }} class="d-flex flex-column align-items-center justify-content-center">
                                    <p style={{ zIndex: 10 }} className='noDataText'>No Data Found</p>
                                </div>
                            } */}
                        </div>

                    </div>
                }
                {
                    rightSet.length == 0 && noData == true &&
                    <div style={{ width: "100%", height: "68vh" }} class="d-flex flex-column align-items-center justify-content-center">
                        <p style={{ zIndex: 10 }} className='noDataText'>No Data Found</p>
                    </div>
                }

                {/* 7th Level Cards */}
            </div>

            <div className="yearStatus" style={{ position: "absolute", top: "-10px", right: 0, margin: 10, backgroundColor: "#f1f9f8" }}>

                <div className="d-flex align-items-center justify-content-center my-1">
                    <i className={activeArrowUp ? "activeArrow ri-arrow-up-s-line" : "ri-arrow-up-s-line"} style={{ fontSize: "20px", cursor: "pointer", }}></i>
                </div>
                <div className="yearStatusInner" ref={yearStatusInnerRef} onScroll={() => handleYearScroll()}>
                    {
                        rightSet.length > 0 && rightSet.map((rItem, ridx) =>
                            <div key={ridx} className="d-flex flex-column">
                                <h5 ref={setRef("yearRef"+rItem.year)}  className="thisYearText mb-0">{rItem.year}</h5>
                                {
                                    rItem.months.map((mItem) =>
                                        <span className={inView.includes(rItem.year + "-" + mItem) ? "thisMonthTextActive thisMonthText" : "thisMonthText"} onClick={(e) => handleMonthYearData(rItem.year, mItem)}>
                                            {monthNames[parseInt(mItem)]}
                                        </span>
                                    )
                                }
                            </div>
                        )
                    }
                </div>

                <div className="d-flex align-items-center justify-content-center my-1">
                    <i className={activeArrowDown ? "activeArrow ri-arrow-down-s-line" : "ri-arrow-down-s-line"} style={{ fontSize: "20px", cursor: "pointer" }}></i>
                </div>
            </div>
        </div>
    )
}